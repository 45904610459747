import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import styled from "styled-components";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledMainAreaContainer,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  Button,
} from "../../components/FormElements";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getAuthData, getReturnUrl } from "../../utils";

const StyledRedeemableBalance = styled.p`
  font-size: 14px;
  color: green;
  margin: 0px;
  padding: 0px;
`;

const WithdrawFundsCreate = () => {
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: "",
    responseMessage: "",
    banksId: "",
    walletType: "GST",
    amount: "",
  });

  const {
    userProfile: {
      nonGstWalletStatus,
      redeemableNongstBalance,
      redeemableGstBalance,
      blockedAmount,
    },
  } = getAuthData();

  const readBankAccounts = async () => {
    try {
      const response = await API.get(
        `/payments/bank-accounts?pageNumber=1&recordsPerPage=1000`
      );
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data: bankAccounts } = useQuery({
    queryKey: ["bank_accounts"],
    queryFn: readBankAccounts,
  });

  const banksOptions = [
    {
      name: "-- SELECT BANK --",
      value: "",
    },
  ];
  !isEmpty(bankAccounts) &&
    bankAccounts?.forEach(({ bankName, id, accountNumber }) =>
      banksOptions?.push({ name: `${bankName}, ${accountNumber}`, value: id })
    );

  const walletTypeOptions = [
    {
      name: "-- SELECT WALLET TYPE --",
      value: "",
    },
    {
      name: "GST",
      value: "GST",
    },
    {
      name: "Non-GST",
      value: "Non-GST",
    },
  ];

  const onSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));
    try {
      const response = await API.post("/payments/withdraw-funds", {
        banksId: state.banksId,
        walletType: state.walletType,
        amount: state.amount,
      });
      const { status, message } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.SUCCESS,
          responseMessage: message,
          banksId: "",
          walletType: "GST",
          amount: "",
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            responseStatus: "",
            responseMessage: "",
          }));
          window.location.href = getReturnUrl("create");
        }, 3000);
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const renderPageHeading = () => (
    <PageHeader title="Withdraw Funds" showBackButton={true} />
  );

  const finalAllowedBalance =
    state.walletType === "GST"
      ? parseFloat(redeemableGstBalance - blockedAmount)
      : parseFloat(redeemableNongstBalance - blockedAmount);

  return (
    <>
      {renderPageHeading()}
      <StyledContentContainer>
        <StyledMainAreaContainer>
          <Form
            action="#"
            method="POST"
            onSubmit={onSubmit}
            style={{ maxWidth: "500px" }}
          >
            <Fieldset>
              <Label required>Bank</Label>
              <Dropdown
                value={state.banksId}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    banksId: value,
                  }))
                }
                placeholder="Bank"
                disabled={state.isLoading}
                options={banksOptions}
              />
            </Fieldset>
            {nonGstWalletStatus === "Active" && (
              <Fieldset>
                <Label required>Wallet Type</Label>
                <Dropdown
                  value={state.walletType}
                  onChange={(value) =>
                    setState((prevState) => ({
                      ...prevState,
                      walletType: value,
                    }))
                  }
                  placeholder="Wallet Type"
                  disabled={state.isLoading}
                  options={walletTypeOptions}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Label required>Amount</Label>
              <TextInput
                value={state.amount}
                isNumeric={true}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    amount: value,
                  }))
                }
                placeholder="Amount"
                disabled={state.isLoading}
              />
            </Fieldset>
            {state.walletType && (
              <Fieldset>
                <StyledRedeemableBalance>
                  Redeemable Balance : {formatCurrency(finalAllowedBalance)}
                </StyledRedeemableBalance>
              </Fieldset>
            )}
            {state.responseStatus && state.responseMessage && (
              <Fieldset>
                <MessageBox
                  status={state.responseStatus}
                  message={state.responseMessage}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={state.isLoading}>Create</Button>
            </Fieldset>
          </Form>
        </StyledMainAreaContainer>
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(WithdrawFundsCreate);
