import { memo } from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  width: 100%;
`;

const Logo = memo(() => <StyledImage src='/logo.png' alt='API Exchange' />);

export default Logo;
