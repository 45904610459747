import { memo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';

const StyledLoadingSpinnerContainer = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000000;
  padding: 5% 0%;
  svg {
    font-size: 40px;
    color: ${({ theme: { colors } }) => colors.primaryColor || `#3049f8`};
    margin-bottom: 20px;
  }
  img {
    width: 10%;
  }
  h2 {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0px 0px;
    padding: 0px;
    color: #000;
  }
`;

const LoadingSpinner = memo(() => (
  <StyledLoadingSpinnerContainer>
    <FontAwesomeIcon icon={faRobot} bounce={true} />
    <h2>Loading, Please wait ...</h2>
  </StyledLoadingSpinnerContainer>
));

export default LoadingSpinner;
