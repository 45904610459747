import styles from 'styled-components';

const StyledHeading = styles.h1`
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  padding: 0px;
  margin: 20px 0px;
  text-align: ${({ textalign }) => (textalign ? textalign : 'left')};
`;

const Heading1 = ({ children, textAlign, ...rest }) => (
  <StyledHeading textalign={textAlign} {...rest}>
    {children}
  </StyledHeading>
);

export default Heading1;
