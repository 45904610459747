import { memo } from 'react';
import styled from 'styled-components';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAdd,
  faArrowLeftLong,
  faFileExcel,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';

const StyledPageHeaderContainer = styled.section`
  background-color: #e6e5ff;
  border-bottom: 1px solid #ebebeb;
  padding: 15px;
  margin: 0px;
  display: flex;
  column-gap: 15px;
  h1 {
    font-size: 24px;
    font-weight: normal;
    color: #000000;
    margin: 0px;
    padding: 0px;
  }
`;

const StyledBackButtonContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    color: #000000;
  }
`;

const StyledHeadingContainer = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
`;

const StyledAddButtonContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
    background-color: green;
    padding: 10px 30px;
    border-radius: 100px;
    outline: 0px;
    text-decoration: none;
    &:hover {
      background-color: #0179af;
    }
  }
`;

const StyledReloadButton = styled.button`
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  background-color: #03a9f4;
  padding: 10px 30px;
  border-radius: 100px;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  cursor: pointer;
  &:hover {
    background-color: #0179af;
  }
`;

const StyledExportButton = styled.button`
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  background-color: #673AB7;
  padding: 10px 30px;
  border-radius: 100px;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  cursor: pointer;
  &:hover {
    background-color: #3f1e78;
  }
`;

const PageHeader = memo(
  ({
    title,
    showBackButton = false,
    showAddButton = false,
    addLink = 'add',
    addTitle = 'Add',
    showReloadData = false,
    reloadData = () => {},
    showExportButton = false,
    onClickExport = () => {},
  }) => {
    const navigate = useNavigate();

    const goBack = () => {
      navigate(-1);
    };

    return (
      <StyledPageHeaderContainer>
        {showBackButton && (
          <StyledBackButtonContainer onClick={goBack} title='Go Back'>
            <FontAwesomeIcon icon={faArrowLeftLong} />
          </StyledBackButtonContainer>
        )}
        <StyledHeadingContainer>
          <h1>{title}</h1>
        </StyledHeadingContainer>
        {showAddButton && (
          <StyledAddButtonContainer>
            <NavLink to={addLink} title={addTitle}>
              <FontAwesomeIcon icon={faAdd} /> {addTitle}
            </NavLink>
          </StyledAddButtonContainer>
        )}
        {showExportButton && (
          <StyledAddButtonContainer>
            <StyledExportButton onClick={onClickExport} title={'Export'}>
              <FontAwesomeIcon icon={faFileExcel} /> Export
            </StyledExportButton>
          </StyledAddButtonContainer>
        )}
        {showReloadData && (
          <StyledAddButtonContainer>
            <StyledReloadButton onClick={reloadData} title={'Refresh'}>
              <FontAwesomeIcon icon={faRefresh} /> Refresh
            </StyledReloadButton>
          </StyledAddButtonContainer>
        )}
      </StyledPageHeaderContainer>
    );
  }
);

export default PageHeader;
