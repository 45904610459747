import React from "react";
import styled from "styled-components";

const StyledGridItem = styled.section`
  display: block;
  margin: 0px;
  padding: 0px;
`;

const GridItem = ({ children, ...rest }) => {
  return <StyledGridItem {...rest}>{children}</StyledGridItem>;
};

export default GridItem;
