import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import moment from "moment";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { MessageBox, Dropdown } from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getAuthData, getSearchParams } from "../../utils";

const AvailableMarginsList = () => {
  const [fOperatorsId, setFoperatorsId] = useState("");
  const [fCirclesId, setFcirclesId] = useState("");
  const [fCategoriesId, setFcategoriesId] = useState("");
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const readMeta = async () => {
    try {
      const response = await API.get(`/meta`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const readData = async () => {
    try {
      const response = await API.get(
        `/buyer/available-margins?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fOperatorsId=${fOperatorsId}&fCirclesId=${fCirclesId}&fCategoriesId=${fCategoriesId}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data: meta } = useQuery({
    queryKey: ["meta"],
    queryFn: readMeta,
  });

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["available_margins", paginationData],
    queryFn: readData,
    cacheTime: 0,
    staleTime: Infinity,
  });

  const reloadData = async () => await refetch();

  const filterData = async (e) => {
    e.preventDefault();
    await refetch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setFoperatorsId("");
    setFcirclesId("");
    setFcategoriesId("");
    refetch();
  };

  const renderPageHeading = () => (
    <PageHeader
      title="Available Margins"
      showAddButton={false}
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  const operatorsOptions = [
    {
      name: "-- SELECT OPERATOR --",
      value: "",
    },
  ];
  !isEmpty(meta?.operators) &&
    meta?.operators?.forEach(({ operatorName, id }) =>
      operatorsOptions?.push({ name: operatorName, value: id })
    );

  const circlesOptions = [
    {
      name: "-- SELECT CIRCLE --",
      value: "",
    },
  ];
  !isEmpty(meta?.circles) &&
    meta?.circles?.forEach(({ circleName, id }) =>
      circlesOptions?.push({ name: circleName, value: id })
    );

  const categoriesOptions = [
    {
      name: "-- SELECT CATEGORY --",
      value: "",
    },
  ];
  !isEmpty(meta?.categories) &&
    meta?.categories?.forEach(({ category, id }) =>
      categoriesOptions?.push({ name: category, value: id })
    );

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  const {
    userProfile: { nonGstWalletStatus },
  } = getAuthData();

  return (
    <>
      <Loader isLoading={isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <Dropdown
                value={fOperatorsId}
                onChange={setFoperatorsId}
                placeholder="Operator"
                disabled={isLoading}
                options={operatorsOptions}
              />
              <Dropdown
                value={fCirclesId}
                onChange={setFcirclesId}
                placeholder="Circle"
                disabled={isLoading}
                options={circlesOptions}
              />
              <Dropdown
                value={fCategoriesId}
                onChange={setFcategoriesId}
                placeholder="Category"
                disabled={isLoading}
                options={categoriesOptions}
              />
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "Operator",
                  dataSelector: "operatorName",
                  dataType: "string",
                },
                {
                  title: "Circle",
                  dataSelector: "circleName",
                  dataType: "string",
                },
                {
                  title: "Category",
                  dataSelector: "category",
                  dataType: "string",
                },
                {
                  title: "Margin Type",
                  dataSelector: "marginType",
                  dataType: "string",
                  shouldShow: nonGstWalletStatus === "Active",
                },
                {
                  title: "Amount",
                  dataSelector: "amount",
                  dataType: "number",
                },
                {
                  title: "Margin",
                  dataSelector: "processedMargin",
                  dataType: "string",
                  cellrenderer: (value) => `${value}%`,
                },
                {
                  title: "Available Limit",
                  dataSelector: "availableLimit",
                  dataType: "number",
                  cellrenderer: (value) => formatCurrency(value),
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(AvailableMarginsList);
