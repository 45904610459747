import { memo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const StyledLoaderSection = styled.section`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 50%);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 40px;
    color: #ffffff;
    margin-bottom: 20px;
  }
`;

const Loader = memo(
  ({ isLoading }) =>
    isLoading && (
      <StyledLoaderSection>
        <FontAwesomeIcon icon={faSpinner} spin={true} />
      </StyledLoaderSection>
    )
);

export default Loader;
