import { memo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledList = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  font-size: 13px;
  font-weight: normal;
  color: #838383;
  text-decoration: none;
  &:hover {
    color: #000000;
  }
`;

const FooterLinks = memo(() => (
  <StyledList>
    <StyledLink to="/privacy-policy" title="Privacy Policy" target="_blank">
      Privacy Policy
    </StyledLink>
    <StyledLink
      to="/terms-and-conditions"
      title="Terms &amp; Conditions"
      target="_blank"
    >
      Terms &amp; Conditions
    </StyledLink>
    <StyledLink to="/refund-policy" title="Refund Policy" target="_blank">
      Refund Policy
    </StyledLink>
    <StyledLink to="/contact" title="Contact" target="_blank">
      Contact
    </StyledLink>
  </StyledList>
));

export default FooterLinks;
