import { memo } from 'react';
import styled from 'styled-components';

const StyledTableHeading = styled.th`
  font-size: 14px;
  font-weight: normal;
  text-align: ${({ textalign }) => (textalign ? textalign : `left`)};
  background-color: #000000;
  color: #ffffff;
  padding: ${({ issticky }) => (issticky === 'Yes' ? '0px' : '15px')};
  margin: 0px;
  top: 0px;
  ${({ stickyalign }) =>
    stickyalign === 'left' ? `left: 0px;` : `right: 0px;`}
  position: ${({ issticky }) => (issticky === 'Yes' ? 'sticky' : 'static')};
  width: ${({ width }) => (width ? width : 'auto')};
  min-width: 60px;
`;

const StickyContainer = styled.section`
  ${({ stickyalign }) =>
    stickyalign === 'left'
      ? `border-right: 1px solid #464646;`
      : `border-left: 1px solid #464646;`}
  ${({ isdummy }) => (isdummy === 'Yes' ? `padding: 25px;` : `padding: 15px;`)}
`;

const TableHeading = memo(
  ({
    children,
    width,
    textAlign,
    isSticky = 'No',
    stickyAlign = 'left',
    isDummy = 'No',
    onClick = () => {},
  }) => (
    <StyledTableHeading
      issticky={isSticky}
      textalign={textAlign}
      stickyalign={stickyAlign}
      width={width}
      isdummy={isDummy}
      onClick={onClick}
    >
      {isSticky === 'Yes' ? (
        <StickyContainer stickyalign={stickyAlign} isdummy={isDummy}>
          {children}
        </StickyContainer>
      ) : (
        children
      )}
    </StyledTableHeading>
  )
);

export default TableHeading;
