import { memo } from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  width: ${({ width }) => width || '100%'};
  height: ${({ width }) => (width ? 'auto' : '100%')};
  margin: 0;
  padding: 0;
`;

const Image = memo(({ source, alt, ...rest }) => (
  <StyledImage src={source} alt={alt} {...rest} />
));

export default Image;
