import { memo } from 'react';
import styled from 'styled-components';

const StyledForm = styled.form`
  display: block;
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
`;

const Form = memo(({ children, ...rest }) => (
  <StyledForm {...rest}>{children}</StyledForm>
));

export default Form;
