import { memo } from "react";
import { StyledHeader } from "../styled";
import WalletSection from "../WalletSection/WalletSection";
import AccountSection from "../AccountSection/AccountSection";
import LogoutSection from "../LogoutSection/LogoutSection";

const Header = memo(
  ({
    fullName,
    profilePhoto,
    onClickLogout,
    gstBuyerWallet,
    gstSellerWallet,
    nonGstBuyerWallet,
    nonGstSellerWallet,
  }) => {
    return (
      <StyledHeader>
        <WalletSection
          gstBuyerWallet={gstBuyerWallet}
          gstSellerWallet={gstSellerWallet}
          nonGstBuyerWallet={nonGstBuyerWallet}
          nonGstSellerWallet={nonGstSellerWallet}
        />
        <AccountSection fullName={fullName} profilePhoto={profilePhoto} />
        <LogoutSection onClickLogout={onClickLogout} />
      </StyledHeader>
    );
  }
);

export default Header;
