import { memo } from "react";
import styled from "styled-components";

const StyledFieldset = styled.fieldset`
  width: ${(props) => (props.forLegend ? `calc(100% - 62px)` : `auto`)};
  height: auto;
  margin: 0px 0px 20px 0px;
  padding: ${(props) => (props.forLegend ? `25px 30px` : `0px`)};
  border: ${(props) => (props.forLegend ? `1px solid #e1e1e1` : `0px`)};
  border-radius: 0px;
  position: relative;
  display: ${(props) => props.display || "flex"};
  flex-direction: row;
  align-items: center;
`;

const Fieldset = memo(({ children, display = "flex", ...rest }) => {
  return (
    <StyledFieldset {...rest} display={display}>
      {children}
    </StyledFieldset>
  );
});

export default Fieldset;
