import { memo } from 'react';
import styled from 'styled-components';
import { Image } from '../Image';

const StyledNoDataContainer = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000000;
  margin: 5% 0%;
  img {
    width: 15%;
  }
  h2 {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0px 0px;
    padding: 0px;
    text-transform: capitalize;
    color: #000;
  }
`;

const NoData = memo(({ message = 'No records found' }) => (
  <StyledNoDataContainer>
    <Image source={'/no-data.png'} alt={'No Data'} />
    <h2>{message}</h2>
  </StyledNoDataContainer>
));

export default NoData;
