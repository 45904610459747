import { useState } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  useIsMutating,
} from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faSearch,
  faX,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import moment from "moment";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
  StyledStatusPill,
  StyledActionItemButtons,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  MessageBox,
  Dropdown,
  TextInput,
  CustomConfirmButton,
} from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getAuthData, getSearchParams } from "../../utils";

const SellerTransactionsPendingList = () => {
  const {
    userProfile: { nonGstWalletStatus },
  } = getAuthData();
  const queryClient = useQueryClient();
  const [fOperatorsId, setFoperatorsId] = useState("");
  const [fCirclesId, setFcirclesId] = useState("");
  const [fCategoriesId, setFcategoriesId] = useState("");
  const [fRechargeNumber, setFrechargeNumber] = useState("");
  const [fFromDate, setFfromDate] = useState("");
  const [fToDate, setFtoDate] = useState("");
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const updateTransaction = async ({
    id,
    rechargeOperatorsId,
    transactionsStatus,
  }) => {
    try {
      const response = await API.post(`/seller/transactions/pending`, {
        id,
        transactionsStatus,
        rechargeOperatorsId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const readData = async () => {
    try {
      const response = await API.get(
        `/seller/transactions?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fOperatorsId=${fOperatorsId}&fCirclesId=${fCirclesId}&fCategoriesId=${fCategoriesId}&fRechargeStatus=Pending&fRechargeNumber=${fRechargeNumber}&fFromDate=${fFromDate}&fToDate=${fToDate}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["pending_transactions", paginationData],
    queryFn: readData,
    cacheTime: 0,
    staleTime: Infinity,
  });

  const { mutateAsync: updateTransactionMutation } = useMutation({
    mutationFn: updateTransaction,
    onError: (error) => console.error(error),
    onSuccess: async (data) => {
      if (data?.data) {
        queryClient.setQueriesData(["pending_transactions"], data);
      } else {
        reloadData();
      }
    },
  });

  const reloadData = async () => await refetch();

  const readMeta = async () => {
    try {
      const response = await API.get(`/meta`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data: meta } = useQuery({
    queryKey: ["meta"],
    queryFn: readMeta,
  });

  const filterData = async (e) => {
    e.preventDefault();
    await refetch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setFoperatorsId("");
    setFcirclesId("");
    setFcategoriesId("");
    setFrechargeNumber("");
    setFfromDate("");
    setFtoDate("");
    refetch();
  };

  const isMutation = useIsMutating();

  const renderPageHeading = () => (
    <PageHeader
      title="Pending Transactions"
      showAddButton={false}
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  const operatorsOptions = [
    {
      name: "OPERATOR",
      value: "",
    },
  ];
  !isEmpty(meta?.operators) &&
    meta?.operators?.forEach(({ operatorName, id }) =>
      operatorsOptions?.push({ name: operatorName, value: id })
    );

  const circlesOptions = [
    {
      name: "CIRCLE",
      value: "",
    },
  ];
  !isEmpty(meta?.circles) &&
    meta?.circles?.forEach(({ circleName, id }) =>
      circlesOptions?.push({ name: circleName, value: id })
    );

  const categoriesOptions = [
    {
      name: "CATEGORY",
      value: "",
    },
  ];
  !isEmpty(meta?.categories) &&
    meta?.categories?.forEach(({ category, id }) =>
      categoriesOptions?.push({ name: category, value: id })
    );

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      <Loader isLoading={isMutation > 0 || isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <Dropdown
                value={fOperatorsId}
                onChange={setFoperatorsId}
                placeholder="Operator"
                disabled={isLoading}
                options={operatorsOptions}
              />
              <Dropdown
                value={fCirclesId}
                onChange={setFcirclesId}
                placeholder="Circle"
                disabled={isLoading}
                options={circlesOptions}
              />
              <Dropdown
                value={fCategoriesId}
                onChange={setFcategoriesId}
                placeholder="Category"
                disabled={isLoading}
                options={categoriesOptions}
              />
              <TextInput
                value={fRechargeNumber}
                onChange={setFrechargeNumber}
                placeholder="Transactions Id"
                disabled={isLoading}
              />
              <TextInput
                type="date"
                value={fFromDate}
                onChange={setFfromDate}
                placeholder="From Date"
                disabled={isLoading}
              />
              <TextInput
                type="date"
                value={fToDate}
                onChange={setFtoDate}
                placeholder="To Date"
                disabled={isLoading}
              />
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "Operator",
                  dataSelector: "operatorName",
                  dataType: "string",
                },
                {
                  title: "Recharge Number",
                  dataSelector: "rechargeNumber",
                  dataType: "string",
                },
                {
                  title: "Amount",
                  dataSelector: "rechargeAmount",
                  dataType: "number",
                  cellrenderer: (value) => formatCurrency(value),
                },
                {
                  title: "Margin",
                  dataSelector: "sellersMargin",
                  dataType: "string",
                  cellrenderer: (value) => `${value}%`,
                },
                {
                  title: "Status",
                  dataSelector: "sellersStatus",
                  dataType: "string",
                  cellrenderer: (value) => (
                    <StyledStatusPill
                      className={
                        value === "Success"
                          ? "green"
                          : value === "Failed"
                          ? "red"
                          : "yellow"
                      }
                    >
                      {value}
                    </StyledStatusPill>
                  ),
                },
                {
                  title: "Operators Id",
                  dataSelector: "sellersOperatorsId",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "API Transactions Id",
                  dataSelector: "sellersApiTransactionsId",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "Category",
                  dataSelector: "category",
                  dataType: "string",
                },
                {
                  title: "Transaction Type",
                  dataSelector: "transactionType",
                  dataType: "string",
                  shouldShow: nonGstWalletStatus === "Active",
                },
                {
                  title: "Circle",
                  dataSelector: "circleName",
                  dataType: "string",
                },
                {
                  title: "Transaction Time",
                  dataSelector: "createdOn",
                  dataType: "string",
                  cellrenderer: (value) =>
                    moment(value).format("Do MMM YYYY, hh:mm:ss A"),
                },
                {
                  title: "",
                  dataSelector: "id",
                  canSort: false,
                  isSticky: "Yes",
                  stickyAlign: "right",
                  isDummy: "Yes",
                  textAlign: "center",
                  width: "max-content",
                  cellrenderer: (value, item) => (
                    <StyledActionItemButtons>
                      <CustomConfirmButton
                        showIcon={true}
                        showTitle={false}
                        isSellerUpdatePending={true}
                        icon={faCheckCircle}
                        color="green"
                        hoverColor="#005a00"
                        title="Success Transaction"
                        proceedButtonText="Confirm"
                        message="Are you sure, you want to mark this transaction as success ?"
                        onClick={async (state) => {
                          await updateTransactionMutation({
                            id: value,
                            transactionsStatus: "Success",
                            ...state,
                          });
                        }}
                      />
                      <CustomConfirmButton
                        showIcon={true}
                        showTitle={false}
                        icon={faXmarkCircle}
                        title="Fail Transaction"
                        proceedButtonText="Confirm"
                        message="Are you sure, you want to mark this transaction as failed ?"
                        onClick={async () => {
                          await updateTransactionMutation({
                            id: value,
                            transactionsStatus: "Failed",
                          });
                        }}
                      />
                    </StyledActionItemButtons>
                  ),
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(SellerTransactionsPendingList);
