import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledMainAreaContainer,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  Button,
} from "../../components/FormElements";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { getReturnUrl } from "../../utils";

const BankAccountsCreate = () => {
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: "",
    responseMessage: "",
    bankName: "",
    payeeName: "",
    accountNumber: "",
    ifscCode: "",
  });

  const readMeta = async () => {
    try {
      const response = await API.get(`/meta`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data: meta } = useQuery({
    queryKey: ["meta"],
    queryFn: readMeta,
  });

  const bankNameOptions = [
    {
      name: "-- SELECT BANK --",
      value: "",
    },
  ];
  !isEmpty(meta?.banks) &&
    meta?.banks?.forEach(({ bankName }) =>
      bankNameOptions?.push({ name: bankName, value: bankName })
    );

  const onSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));
    try {
      const response = await API.post("/payments/bank-accounts", {
        bankName: state.bankName,
        payeeName: state.payeeName,
        accountNumber: state.accountNumber,
        ifscCode: state.ifscCode,
      });
      const { status, message } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.SUCCESS,
          responseMessage: message,
          bankName: "",
          payeeName: "",
          accountNumber: "",
          ifscCode: "",
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            responseStatus: "",
            responseMessage: "",
          }));
          window.location.href = getReturnUrl("create");
        }, 3000);
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const renderPageHeading = () => (
    <PageHeader title="Add Bank Account" showBackButton={true} />
  );

  return (
    <>
      {renderPageHeading()}
      <StyledContentContainer>
        <StyledMainAreaContainer>
          <Form
            action="#"
            method="POST"
            onSubmit={onSubmit}
            style={{ maxWidth: "500px" }}
          >
            <Fieldset>
              <Label required>Bank</Label>
              <Dropdown
                value={state.bankName}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    bankName: value,
                  }))
                }
                placeholder="Bank"
                disabled={state.isLoading}
                options={bankNameOptions}
              />
            </Fieldset>
            <Fieldset>
              <Label required>Payee Name</Label>
              <TextInput
                value={state.payeeName}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    payeeName: value,
                  }))
                }
                placeholder="Payee Name"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>Account Number</Label>
              <TextInput
                value={state.accountNumber}
                isNumeric={true}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    accountNumber: value,
                  }))
                }
                placeholder="Account Number"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>IFSC Code</Label>
              <TextInput
                value={state.ifscCode}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    ifscCode: value,
                  }))
                }
                placeholder="IFSC Code"
                disabled={state.isLoading}
              />
            </Fieldset>
            {state.responseStatus && state.responseMessage && (
              <Fieldset>
                <MessageBox
                  status={state.responseStatus}
                  message={state.responseMessage}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={state.isLoading}>Create</Button>
            </Fieldset>
          </Form>
        </StyledMainAreaContainer>
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(BankAccountsCreate);
