import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledStatusPill,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { MessageBox } from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getAuthData, getSearchParams } from "../../utils";

const WithdrawFundsList = () => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const readData = async () => {
    try {
      const response = await API.get(
        `/payments/withdraw-funds?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["withdraw_funds", paginationData],
    queryFn: readData,
    cacheTime: 0,
    staleTime: Infinity,
  });

  const reloadData = async () => await refetch();

  const exportData = async () => {
    exportFromJSON({
      data: data.data,
      fileName: "payout_report",
      exportType: exportFromJSON.types.csv,
    });
  };

  const renderPageHeading = () => (
    <PageHeader
      title="Withdraw Funds History"
      showAddButton={true}
      addLink="create"
      addTitle="Withdraw Funds"
      showReloadData={true}
      reloadData={reloadData}
      showExportButton={true}
      onClickExport={exportData}
    />
  );

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  const {
    userProfile: { nonGstWalletStatus },
  } = getAuthData();

  return (
    <>
      <Loader isLoading={isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "Bank Name",
                  dataSelector: "bankName",
                  dataType: "string",
                },
                {
                  title: "Payee Name",
                  dataSelector: "payeeName",
                  dataType: "string",
                },
                {
                  title: "Account Number",
                  dataSelector: "accountNumber",
                  dataType: "string",
                },
                {
                  title: "IFSC Code",
                  dataSelector: "ifscCode",
                  dataType: "string",
                },
                {
                  title: "Wallet Type",
                  dataSelector: "walletType",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                  shouldShow: nonGstWalletStatus === "Active",
                },
                {
                  title: "Amount",
                  dataSelector: "amount",
                  dataType: "number",
                  cellrenderer: (value) => formatCurrency(value),
                },
                {
                  title: "UTR Number",
                  dataSelector: "utrNumber",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "Status",
                  dataSelector: "approvalStatus",
                  dataType: "string",
                  cellrenderer: (value, item) => (
                    <StyledStatusPill
                      className={
                        value === "Approved"
                          ? "green"
                          : value === "Rejected"
                          ? "red"
                          : "yellow"
                      }
                    >
                      {value}
                    </StyledStatusPill>
                  ),
                },
                {
                  title: "Remark",
                  dataSelector: "remark",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "Created",
                  dataSelector: "createdOn",
                  dataType: "string",
                  cellrenderer: (value) =>
                    moment(value).format("Do MMM YYYY, hh:mm:ss A"),
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(WithdrawFundsList);
