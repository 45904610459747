import { memo, useState, useEffect, useCallback } from "react";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledMainAreaContainer,
  StyledButtonAsLink,
  StyledAuthLinks,
  StyledPara,
  StyledTextHighlight,
} from "../../components/styled";
import Loader from "../../components/Loader/Loader";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Form,
  Fieldset,
  Label,
  OTPInput,
  MessageBox,
  Button,
  PasswordInput,
} from "../../components/FormElements";
import API from "../../api";
import { OTP_VERIFICATION_TYPES, RESPONSE_STATUSES } from "../../constants";
import { getAuthData } from "../../utils";

const RechargeAutomationAPICredentials = memo(() => {
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: "",
    responseMessage: "",
    routeStage: "Request",
    oneTimePassword: "",
    requestToken: "",
    apiId: "",
    apiToken: "",
  });
  const { userProfile } = getAuthData();

  const readData = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));
    try {
      const response = await API.get(`/buyer/api-credentials`);
      const { status, message, data } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        setState((prevState) => ({
          ...prevState,
          apiId: data?.apiId,
          apiToken: data?.apiToken,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));
    try {
      let response;
      if (state.routeStage === "Request") {
        response = await API.put(`/buyer/api-credentials`);
      } else {
        response = await API.post(`/authentication/verify-otp`, {
          mobileNumber: userProfile.mobileNumber,
          requestType: OTP_VERIFICATION_TYPES.GENERATE_API_CREDENTIALS,
          requestToken: state.requestToken,
          oneTimePassword: state.oneTimePassword,
        });
      }
      const { status, message, data, token } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        if (state.routeStage === "Request") {
          setState((prevState) => ({
            ...prevState,
            routeStage: "OTP",
            requestToken: token,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            responseStatus: RESPONSE_STATUSES.SUCCESS,
            responseMessage: message,
            routeStage: "Request",
            requestToken: "",
            oneTimePassword: "",
            apiId: data?.apiId,
            apiToken: data?.apiToken,
          }));
          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              responseStatus: "",
              responseMessage: "",
            }));
          }, 3000);
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
          requestToken: token,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const resendOTP = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));
    try {
      const response = await API.post("/authentication/resend-otp", {
        requestToken: state.requestToken,
        mobileNumber: userProfile?.mobileNumber,
      });
      const { status, message, token } = response.data;
      setState((prevState) => ({
        ...prevState,
        responseStatus: status,
        responseMessage: message,
        requestToken: token,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    readData();
  }, [readData]);

  const renderPageHeading = () => <PageHeader title="API Credentials" />;

  return (
    <>
      <Loader isLoading={state.isLoading} />
      {renderPageHeading()}
      <StyledContentContainer>
        <StyledMainAreaContainer>
          <Form
            action="#"
            method="POST"
            onSubmit={onSubmit}
            style={{ maxWidth: "500px" }}
          >
            <Fieldset>
              <Label>API Id</Label>
              <PasswordInput
                value={state.apiId}
                placeholder="API Id"
                disabled={true}
                copy
              />
            </Fieldset>
            <Fieldset>
              <Label>API Token</Label>
              <PasswordInput
                value={state.apiToken}
                placeholder="API Token"
                disabled={true}
                copy
              />
            </Fieldset>
            {state.routeStage === "OTP" && (
              <>
                <Fieldset>
                  <StyledPara>
                    Please enter your OTP sent to{" "}
                    <StyledTextHighlight>
                      {userProfile?.mobileNumber}
                    </StyledTextHighlight>
                  </StyledPara>
                </Fieldset>
                <Fieldset>
                  <OTPInput
                    value={state.oneTimePassword}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        oneTimePassword: value,
                      }))
                    }
                    placeholder="OTP"
                    disabled={state.isLoading}
                  />
                </Fieldset>
              </>
            )}
            {state.responseStatus && state.responseMessage && (
              <Fieldset>
                <MessageBox
                  status={state.responseStatus}
                  message={state.responseMessage}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={state.isLoading} type="submit">
                {state.routeStage === "OTP"
                  ? "Verify & Proceed"
                  : "Generate New Keys"}
              </Button>
            </Fieldset>
            {state.routeStage === "OTP" && (
              <Fieldset>
                <StyledAuthLinks>
                  <StyledButtonAsLink type="button" onClick={resendOTP}>
                    Resend OTP
                  </StyledButtonAsLink>
                </StyledAuthLinks>
              </Fieldset>
            )}
          </Form>
        </StyledMainAreaContainer>
      </StyledContentContainer>
    </>
  );
});

export default withAppHOC(RechargeAutomationAPICredentials);
