import { memo } from 'react';
import styled from 'styled-components';

const StyledButtonGroup = styled.section`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: block;
`;

const ButtonGroup = memo(({ children, ...rest }) => (
  <StyledButtonGroup {...rest}>{children}</StyledButtonGroup>
));

export default ButtonGroup;
