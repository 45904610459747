import { memo, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogBox } from '../DialogBoxes';

const StyledIconButton = styled.button`
  font-size: 14px;
  font-weight: normal;
  padding: 0px;
  margin: 0px;
  color: ${({ color }) => (color ? color : '#ff4343')};
  background-color: transparent;
  border: 0px;
  outline: 0px;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    color: ${({ hovercolor }) => (hovercolor ? hovercolor : `#ff0000`)};
  }
`;

const StyledTextButton = styled.button`
  font-size: 14px;
  font-weight: normal;
  color: ${({ color }) => (color ? color : '#ff4343')};
  background-color: transparent;
  border: 1px solid ${({ color }) => (color ? color : '#ff4343')};
  border-radius: 5px;
  margin: 0px;
  padding: 10px 15px;
  outline: 0px;
  cursor: pointer;
  &:hover {
    border: 1px solid
      ${({ hovercolor }) => (hovercolor ? hovercolor : '#ff0000')};
    color: ${({ hovercolor }) => (hovercolor ? hovercolor : '#ff0000')};
  }
  &:disabled {
    border: 1px solid #c1c1c1;
    color: #c1c1c1;
    cursor: not-allowed;
  }
`;

const CustomConfirmButton = memo(
  ({
    showIcon = true,
    showTitle = true,
    icon,
    title = 'Delete',
    buttonTitle = '',
    onClick,
    color = '',
    hoverColor = '',
    buttonStyle,
    showTextButton = false,
    disabled,
    confirmMessage,
    proceedButtonText,
    onClose = null,
    isBuyerComplaint = false,
    ...rest
  }) => {
    const [confirmBoxStatus, setConfirmBoxStatus] = useState(false);

    const onClickIcon = () => {
      setConfirmBoxStatus(true);
    };

    const onProceed = (state) => {
      setConfirmBoxStatus(false);
      onClick(state);
    };

    const onCancel = () => {
      setConfirmBoxStatus(false);
      onClose && onClose();
    };

    return (
      <>
        {showTextButton ? (
          <StyledTextButton
            onClick={onClickIcon}
            color={color}
            hovercolor={hoverColor}
            style={buttonStyle}
            disabled={disabled}
          >
            {showIcon && <FontAwesomeIcon icon={icon ? icon : faTrashCan} />}
            {showTitle && ` ${buttonTitle ? buttonTitle : title}`}
          </StyledTextButton>
        ) : (
          <StyledIconButton
            onClick={onClickIcon}
            color={color}
            hovercolor={hoverColor}
            style={buttonStyle}
            disabled={disabled}
          >
            {showIcon && <FontAwesomeIcon icon={icon ? icon : faTrashCan} />}
            {showTitle && ` ${buttonTitle ? buttonTitle : title}`}
          </StyledIconButton>
        )}
        <ConfirmDialogBox
          status={confirmBoxStatus}
          onClickProceed={onProceed}
          onClickCancel={onCancel}
          icon={icon}
          title={title}
          color={color}
          hoverColor={hoverColor}
          message={confirmMessage}
          proceedButtonText={proceedButtonText ? proceedButtonText : title}
          isBuyerComplaint={isBuyerComplaint}
          {...rest}
        />
      </>
    );
  }
);

export default CustomConfirmButton;
