import { memo } from "react";
import styled from "styled-components";
import { RESPONSE_STATUSES } from "../../constants";

const StyledMessageBox = styled.section`
  display: block;
  width: calc(100% - 30px);
  height: auto;
  font-size: 14px;
  color: ${({ status }) =>
    status === RESPONSE_STATUSES.SUCCESS
      ? "#319500"
      : status === "Rejected"
      ? "#795548"
      : "#ff0000"};
  background-color: ${({ status }) =>
    status === RESPONSE_STATUSES.SUCCESS
      ? "#c5ffc7"
      : status === "Rejected"
      ? "#ffd599"
      : "#ffebeb"};
  text-align: center;
  margin: 0px;
  padding: 15px;
  border-radius: 5px;
`;

const MessageBox = memo(
  ({ status, message, ...rest }) =>
    message && (
      <StyledMessageBox status={status} {...rest}>
        {message}
      </StyledMessageBox>
    )
);

export default MessageBox;
