import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBank,
  faCheck,
  faExchange,
  faFlag,
  faMoneyBills,
  faMoneyBillTransfer,
  faWallet,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../hoc";
import { StyledContentContainer } from "../../components/styled";
import { MessageBox } from "../../components/FormElements";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency } from "../../utils";

const StyledHeading = styled.h1`
  font-size: 24px;
  font-weight: normal;
  color: rgb(0, 0, 0);
  margin: 0px 0px 20px 0px;
  padding: 0px;
`;

const StyledStatsContainerOuter = styled.section`
  width: 100%;
  height: auto;
  margin: 0px 0px 20px;
  padding: 0px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledStatsContainer = styled.section`
  width: auto;
  height: auto;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

const StyledStatsSection = styled.section`
  width: 300px;
  height: 100px;
  background: linear-gradient(
    to top right,
    ${({
      theme: {
        colors: { primaryColor },
      },
    }) => (primaryColor ? primaryColor : `#0095ff`)},
    ${({
      theme: {
        colors: { secondaryColor },
      },
    }) => (secondaryColor ? secondaryColor : `#0078ce`)},
    ${({
      theme: {
        colors: { secondaryColor },
      },
    }) => (secondaryColor ? secondaryColor : `#0078ce`)},
    ${({
      theme: {
        colors: { primaryColor },
      },
    }) => (primaryColor ? primaryColor : `#0095ff`)}
  );
  padding: 0px;
  margin: 0px;
  display: grid;
  border-radius: 10px;
  grid-template-columns: max-content auto;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledStatsIcon = styled.section`
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  svg {
    width: 30px;
    height: 30px;
    color: #ffffff;
    border: 5px solid #ffffff;
    padding: 15px;
    border-radius: 100px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledStatsCounter = styled.section`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
`;

const StyledStatsHeading = styled.p`
  font-size: 14px;
  font-weight: normal;
  margin: 0px;
  padding: 15px;
  text-align: left;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledStatsCount = styled.h3`
  font-size: 22px;
  font-weight: normal;
  margin: 0px;
  padding: 18px 15px 0px 15px;
`;

const Dashboard = () => {
  const readData = async () => {
    try {
      const response = await API.get(`/dashboard`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, error } = useQuery({
    queryKey: ["dashboard"],
    queryFn: readData,
    cacheTime: 0,
    staleTime: Infinity,
  });

  if (error) {
    return (
      <>
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  const stats = data && data?.length > 0 ? data[0] : {};

  return (
    stats && (
      <StyledContentContainer>
        <StyledHeading>Sales Summary</StyledHeading>
        <StyledStatsContainerOuter>
          <StyledStatsContainer>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faCheck} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.salesSuccessRecharges)}
                </StyledStatsCount>
                <StyledStatsHeading title="Success Recharges">
                  Success Recharges
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faXmark} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.salesFailedRecharges)}
                </StyledStatsCount>
                <StyledStatsHeading title="Failed Recharges">
                  Failed Recharges
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faExchange} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.salesPendingRecharges)}
                </StyledStatsCount>
                <StyledStatsHeading title="Pending Recharges">
                  Pending Recharges
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faFlag} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.salesPendingComplaints)}
                </StyledStatsCount>
                <StyledStatsHeading title="Complaint Recharges">
                  Complaint Recharges
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
          </StyledStatsContainer>
        </StyledStatsContainerOuter>
        <StyledHeading>Purchase Summary</StyledHeading>
        <StyledStatsContainerOuter>
          <StyledStatsContainer>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faCheck} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.purchaseSuccessRecharges)}
                </StyledStatsCount>
                <StyledStatsHeading title="Success Recharges">
                  Success Recharges
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faXmark} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.purchaseFailedRecharges)}
                </StyledStatsCount>
                <StyledStatsHeading title="Failed Recharges">
                  Failed Recharges
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faExchange} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.purchasePendingRecharges)}
                </StyledStatsCount>
                <StyledStatsHeading title="Pending Recharges">
                  Pending Recharges
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faFlag} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.purchasePendingComplaints)}
                </StyledStatsCount>
                <StyledStatsHeading title="Complaint Recharges">
                  Complaint Recharges
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
          </StyledStatsContainer>
        </StyledStatsContainerOuter>
        <StyledHeading>Todays Summary</StyledHeading>
        <StyledStatsContainerOuter>
          <StyledStatsContainer>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faMoneyBills} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.todaysSalesTotal)}
                </StyledStatsCount>
                <StyledStatsHeading title="Total Sale">
                  Total Sale
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faMoneyBillTransfer} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.todaysPurchaseTotal)}
                </StyledStatsCount>
                <StyledStatsHeading title="Total Purchase">
                  Total Purchase
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faWallet} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.todaysWalletTopup)}
                </StyledStatsCount>
                <StyledStatsHeading title="Wallet Topup Amount">
                  Wallet Topup Amount
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faBank} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.todaysWalletRedeem)}
                </StyledStatsCount>
                <StyledStatsHeading title="Withdrawal Amount">
                  Withdrawal Amount
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
          </StyledStatsContainer>
        </StyledStatsContainerOuter>
        <StyledHeading>Current Month Summary</StyledHeading>
        <StyledStatsContainerOuter>
          <StyledStatsContainer>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faMoneyBills} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.thismonthSalesInvoiceAmount)}
                </StyledStatsCount>
                <StyledStatsHeading title="Total Sale">
                  Total Sale
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faMoneyBillTransfer} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.thismonthPurchaseInvoice)}
                </StyledStatsCount>
                <StyledStatsHeading title="Total Purchase">
                  Total Purchase
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
          </StyledStatsContainer>
        </StyledStatsContainerOuter>
        <StyledHeading>Last Month Summary</StyledHeading>
        <StyledStatsContainerOuter>
          <StyledStatsContainer>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faMoneyBills} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.lastMonthSalesInvoice)}
                </StyledStatsCount>
                <StyledStatsHeading title="Total Sale">
                  Total Sale
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
            <StyledStatsSection>
              <StyledStatsIcon>
                <FontAwesomeIcon icon={faMoneyBillTransfer} />
              </StyledStatsIcon>
              <StyledStatsCounter>
                <StyledStatsCount>
                  {formatCurrency(stats?.lastMonthPurchaseInvoice)}
                </StyledStatsCount>
                <StyledStatsHeading title="Total Purchase">
                  Total Purchase
                </StyledStatsHeading>
              </StyledStatsCounter>
            </StyledStatsSection>
          </StyledStatsContainer>
        </StyledStatsContainerOuter>
      </StyledContentContainer>
    )
  );
};

export default withAppHOC(Dashboard);
