import { memo } from 'react';
import styled from 'styled-components';

const StyledTableHeader = styled.thead`
  margin: 0px;
  padding: 0px;
`;

const TableHeader = memo(({ children }) => (
  <StyledTableHeader>{children}</StyledTableHeader>
));

export default TableHeader;
