import { useState } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  useIsMutating,
} from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledActionItemButtons,
  StyledEditItemButton,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Switch,
  MessageBox,
  CustomConfirmButton,
  SearchInput,
} from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { API_CONFIG, RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getSearchParams } from "../../utils";

const SellerAPISettingsList = () => {
  const { API_BASE_URL } = API_CONFIG;
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const readData = async () => {
    try {
      const response = await API.get(
        `/seller/api-settings?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}&fetchBalance=Yes`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const updateData = async ({ value, newData }) => {
    try {
      newData.marginStatus = value === true ? `Active` : `Inactive`;
      const response = await API.put(
        `/seller/api-settings/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}&fetchBalance=Yes`,
        newData
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const deleteData = async (id) => {
    try {
      const response = await API.delete(
        `/seller/api-settings/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}&fetchBalance=Yes`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["seller_api_settings", paginationData],
    queryFn: readData,
    cacheTime: 0,
    staleTime: Infinity,
  });

  const reloadData = async () => await refetch();

  const { mutateAsync: updateDataMutation } = useMutation({
    mutationFn: updateData,
    onError: (error) => console.error(error),
    onSuccess: async (data) => {
      if (data?.data) {
        queryClient.setQueriesData(["seller_api_settings"], data);
      } else {
        reloadData();
      }
    },
  });

  const { mutateAsync: deleteDataMutation } = useMutation({
    mutationFn: deleteData,
    onError: (error) => console.error(error),
    onSuccess: (data) => {
      if (data?.data) {
        queryClient.setQueriesData(["seller_api_settings"], data);
      } else {
        reloadData();
      }
    },
  });

  const isMutation = useIsMutating();

  const filterData = async (e) => {
    e.preventDefault();
    await refetch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setSearchQuery("");
    refetch();
  };

  const renderPageHeading = () => (
    <PageHeader
      title="API Settings"
      showAddButton={true}
      addLink="create"
      addTitle="Add API"
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      <Loader isLoading={isMutation > 0 || isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <SearchInput
                value={searchQuery}
                onChange={setSearchQuery}
                disabled={isLoading}
                placeholder="Search API Name"
              />
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "API Name",
                  dataSelector: "apiName",
                  dataType: "string",
                },
                {
                  title: "IP Address",
                  dataSelector: "ipAddress",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "Callback URL",
                  dataSelector: "callbackKey",
                  dataType: "string",
                  cellrenderer: (value) => `${API_BASE_URL}/callback/${value}`,
                },
                {
                  title: "Balance",
                  dataSelector: "balance",
                  dataType: "number",
                  cellrenderer: (value) => formatCurrency(value),
                },
                {
                  title: "Status",
                  dataSelector: "marginStatus",
                  dataType: "string",
                  canSort: false,
                  align: "center",
                  cellrenderer: (value, data) =>
                    !isEmpty(value) && (
                      <Switch
                        value={value === "Active"}
                        onChange={async (value) =>
                          await updateDataMutation({
                            value,
                            newData: { ...data },
                          })
                        }
                      />
                    ),
                },
                {
                  title: "",
                  dataSelector: "id",
                  canSort: false,
                  isSticky: "Yes",
                  stickyAlign: "right",
                  isDummy: "Yes",
                  textAlign: "center",
                  width: "max-content",
                  cellrenderer: (value, item) => (
                    <StyledActionItemButtons>
                      <StyledEditItemButton
                        to={`update/${value}`}
                        title="Update Margin"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledEditItemButton>
                      <CustomConfirmButton
                        showTitle={false}
                        onClick={async () => await deleteDataMutation(value)}
                      />
                    </StyledActionItemButtons>
                  ),
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(SellerAPISettingsList);
