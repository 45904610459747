import { memo } from 'react';
import styled from 'styled-components';

const StyledTableData = styled.td`
  font-size: 14px;
  font-weight: normal;
  margin: 0px;
  padding: ${({ issticky, stickyalign }) =>
    issticky === 'Yes' && stickyalign === 'left' ? '15px 0px' : '15px'};
  text-align: ${({ textalign }) => (textalign ? textalign : `left`)};
  width: ${({ width }) => (width ? width : 'auto')};
  ${({ width }) => width && `max-width: ${width}; min-width: ${width};`}
  position: ${({ issticky }) => (issticky === 'Yes' ? 'sticky' : 'static')};
  color: #000000;
  ${({ issticky }) => issticky === 'Yes' && `z-index: 9;`}
  background-color: ${({ issticky, isselected }) =>
    isselected === 'Yes'
      ? `#f5fbff`
      : issticky === 'Yes'
      ? '#fbfbfb'
      : 'transparent'};
  ${({ stickyalign }) =>
    stickyalign === 'left' ? `left: 0px;` : `right: 0px;`}

  &.align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TableData = memo(
  ({
    children,
    textAlign,
    width,
    isSticky = 'No',
    stickyAlign = 'left',
    isSelected = 'No',
  }) => (
    <StyledTableData
      issticky={isSticky}
      textalign={textAlign}
      stickyalign={stickyAlign}
      isselected={isSelected}
      width={width}
    >
      {children}
    </StyledTableData>
  )
);

export default TableData;
