import { memo, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faSpinner,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { StyledPara } from '../styled';
import { Fieldset } from '../FormElements';

const StyledConfirmDialogBoxContainer = styled.section`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
`;

const StyledOverlay = styled.section`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
  background-color: rgb(0 0 0 / 50%);
`;

const StyledDialogBoxOuter = styled.section`
  width: auto;
  height: auto;
  background-color: #fff;
  padding: 30px;
  margin: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
`;

const StyledDialogBox = styled.section`
  width: 300px;
  height: auto;
  padding: 0px;
  margin: 0px;
  text-align: center;
  @media (max-width: 600px) {
    width: 100%;
    min-width: 300px;
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    margin: 10px 0px 20px 0px;
    padding: 0px;
    text-transform: capitalize;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    color: #000000;
    margin: 0px 0px 30px 0px;
    padding: 0px;
    line-height: 20px;
  }
`;

const StyledIconHolder = styled.section`
  width: 80px;
  height: 80px;
  margin: 0px auto;
  padding: 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? color : `#3049f8`)};
  svg {
    font-size: 40px;
  }
`;

const StyledButtonGroup = styled.section`
  width: 100%;
  height: auto;
  padding: 0px;
  display: flex;
  column-gap: 20px;
  justify-content: center;
  align-items: center;
`;

const StyledCancelButton = styled.button`
  flex: 1;
  font-size: 14px;
  font-weight: normal;
  color: #000;
  border: 0px;
  outline: 0px;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  width: calc(50% - 25px);
  margin: 0px;
  background-color: #f5f5f5;
  &:hover {
    background-color: #e3e3e3;
  }
`;

// const StyledSubmitButton = styled.button`
//   flex: 1;
//   font-size: 14px;
//   font-weight: normal;
//   color: #fff;
//   border: 0px;
//   outline: 0px;
//   padding: 15px;
//   border-radius: 5px;
//   cursor: pointer;
//   width: calc(50% - 25px);
//   margin: 0px;
//   background-color: ${({ color }) => (color ? color : `#ff4343`)};
//   &:hover {
//     background-color: ${({ hovercolor }) =>
//       hovercolor ? hovercolor : `#ff0000`};
//   }
// `;

const WebSocketDialogBox = memo(({ wsState = false, onChange = () => {} }) => {
  const dialogBoxRef = useRef(null);

  return (
    wsState.showModal &&
    createPortal(
      <StyledConfirmDialogBoxContainer>
        <StyledOverlay>
          <StyledDialogBoxOuter ref={dialogBoxRef}>
            <StyledDialogBox>
              {['Connecting', 'Server Connected'].includes(
                wsState?.wsMessage
              ) &&
                !wsState?.isQRDetected && (
                  <StyledIconHolder>
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </StyledIconHolder>
                )}
              {['Lapu Connected'].includes(wsState?.wsMessage) &&
                !wsState?.isQRDetected && (
                  <StyledIconHolder>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: 'forestgreen' }}
                    />
                  </StyledIconHolder>
                )}
              {!['Connecting', 'Server Connected', 'Lapu Connected'].includes(
                wsState?.wsMessage
              ) &&
                !wsState?.isQRDetected && (
                  <StyledIconHolder>
                    <FontAwesomeIcon
                      icon={faXmarkCircle}
                      style={{ color: 'red' }}
                    />
                  </StyledIconHolder>
                )}
              {wsState.wsMessage && <h2>{wsState.wsMessage}</h2>}
              {wsState.isQRDetected && (
                <>
                  <Fieldset>
                    <StyledPara>
                      Please scan the qr code with airtel mitra app
                    </StyledPara>
                    <img
                      src={`data:image/png;base64,${wsState.qrCode}`}
                      alt='QR Code'
                    />
                  </Fieldset>
                </>
              )}
              <StyledButtonGroup>
                <StyledCancelButton
                  title='Cancel'
                  type='button'
                  onClick={() => {
                    onChange({
                      ...wsState,
                      showModal: false,
                      lapusId: null,
                      operatorsId: null,
                      isQRDetected: false,
                      qrCode: '',
                      wsMessage: '',
                    });
                  }}
                >
                  Cancel
                </StyledCancelButton>
              </StyledButtonGroup>
            </StyledDialogBox>
          </StyledDialogBoxOuter>
        </StyledOverlay>
      </StyledConfirmDialogBoxContainer>,
      document.getElementById('modals')
    )
  );
});

export default WebSocketDialogBox;
