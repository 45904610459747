import { memo } from "react";
import styled from "styled-components";

const StyledDropdown = styled.select`
  background-color: #f9fafb;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  padding: 15px;
  margin: 0px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: 100%;
  flex: 2;
  background-color: #f9fafb;
  outline: 0px;
  &:focus {
    outline: 1px solid #dbdbdb;
  }
  &.error {
    border-color: #ff4343;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #e1e1e1;
    border: 1px solid #d3d3d3;
  }
`;

const Dropdown = memo(
  ({
    value = "",
    onChange = () => {},
    disabled = false,
    options = [],
    ...rest
  }) => (
    <StyledDropdown
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      autoComplete="off"
      disabled={disabled}
      {...rest}
    >
      {options &&
        options.length > 0 &&
        options.map(({ name, value }, index) => (
          <option value={value} key={index}>
            {name}
          </option>
        ))}
    </StyledDropdown>
  )
);

export default Dropdown;
