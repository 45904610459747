import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import JSONPretty from "react-json-pretty";
import { withAppHOC } from "../../hoc";
import { StyledContentContainer } from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { TableBuilder } from "../../components/TableElements";
import { API_CONFIG } from "../../constants";
import API from "../../api";
import { isArray } from "lodash";

const StyledBodyContainer = styled.section`
  font-size: 14px;
  font-weight: normal;
  color: #000;
  word-break: break-all;
  padding: 0px;
  line-height: 20px;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  border-radius: 5px;
`;

const StyledHeading = styled.h2`
  font-size: 16px;
  font-weight: normal;
  color: #000;
  background-color: #e5e5e5;
  padding: 15px;
  margin: 0px;
`;

const StyledContent = styled.section`
  padding: 20px;
  @media (max-width: 600px) {
    word-break: break-all;
    overflow-x: scroll;
  }
`;

const StyledApiHeading = styled.h1`
  font-size: 20px;
  font-weight: normal;
  color: #433cf2;
  margin: 0px 0px 20px;
  padding: 0px;
`;

const StyledBodyContainerList = styled.section`
  font-size: 14px;
  font-weight: normal;
  color: #000;
  word-break: break-all;
  padding: 0px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const APIDocumentation = () => {
  const { API_BASE_URL } = API_CONFIG;

  const readMeta = async () => {
    try {
      const response = await API.get(`/meta`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data } = useQuery({
    queryKey: ["meta"],
    queryFn: readMeta,
    cacheTime: 0,
    staleTime: Infinity,
  });

  return (
    <>
      <PageHeader title="API Documentation" />
      <StyledContentContainer>
        <StyledApiHeading>Recharge API</StyledApiHeading>
        <StyledBodyContainer>
          <StyledHeading>API Request</StyledHeading>
          <StyledContent>
            <p>
              <b>Method :</b> GET
            </p>
            <p>
              <b>URL :</b>{" "}
              {data?.nonGstWalletStatus === "Active" ? (
                <code>{`${API_BASE_URL}/public/recharge?apiid=[apiid]&apitoken=[apitoken]&number=[number]&operator=[operator]&amount=[amount]&refnumber=[refnumber]&wallet_type=[wallet_type]`}</code>
              ) : (
                <code>{`${API_BASE_URL}/public/recharge?apiid=[apiid]&apitoken=[apitoken]&number=[number]&operator=[operator]&amount=[amount]&refnumber=[refnumber]`}</code>
              )}
            </p>
            <TableBuilder
              tableHeadings={[
                {
                  title: "Field",
                  dataSelector: "field",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Description",
                  dataSelector: "description",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Mandatory/Optional",
                  dataSelector: "fieldType",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Remark",
                  dataSelector: "remark",
                  dataType: "string",
                  canSort: false,
                },
              ]}
              tableData={
                data?.nonGstWalletStatus === "Active"
                  ? [
                      {
                        field: "apiid",
                        description: "Api id provided by us",
                        fieldType: "Mandatory",
                        remark: "",
                      },
                      {
                        field: "apitoken",
                        description: "Api token provided by us",
                        fieldType: "Mandatory",
                        remark: "",
                      },
                      {
                        field: "number",
                        description: "Recharge mobile/account number",
                        fieldType: "Mandatory",
                        remark: "",
                      },
                      {
                        field: "operator",
                        description: "Our operators id",
                        fieldType: "Mandatory",
                        remark: "",
                      },
                      {
                        field: "amount",
                        description: "Recharge amount",
                        fieldType: "Mandatory",
                        remark: "",
                      },
                      {
                        field: "refnumber",
                        description: "Your transactions id",
                        fieldType: "Mandatory",
                        remark: "",
                      },
                      {
                        field: "wallet_type",
                        description: "GST/Non-GST",
                        fieldType: "Mandatory",
                        remark: "",
                      },
                    ]
                  : [
                      {
                        field: "apiid",
                        description: "Api id provided by us",
                        fieldType: "Mandatory",
                        remark: "",
                      },
                      {
                        field: "apitoken",
                        description: "Api token provided by us",
                        fieldType: "Mandatory",
                        remark: "",
                      },
                      {
                        field: "number",
                        description: "Recharge mobile/account number",
                        fieldType: "Mandatory",
                        remark: "",
                      },
                      {
                        field: "operator",
                        description: "Our operators id",
                        fieldType: "Mandatory",
                        remark: "",
                      },
                      {
                        field: "amount",
                        description: "Recharge amount",
                        fieldType: "Mandatory",
                        remark: "",
                      },
                      {
                        field: "refnumber",
                        description: "Your transactions id",
                        fieldType: "Mandatory",
                        remark: "",
                      },
                    ]
              }
            />
          </StyledContent>
        </StyledBodyContainer>
        <StyledBodyContainer>
          <StyledHeading>Response</StyledHeading>
          <StyledContent>
            <p>
              <b>Response Type :</b> JSON
            </p>
            <JSONPretty
              id="json-pretty"
              data={JSON.stringify({
                status: "Success/Failed/Pending",
                message: "Transaction Information",
                transactionsid: "Our transactions id",
                number: "Recharge number",
                amount: "Recharge amount",
                margin: "Your margin",
                operator: "Operator name",
                operatorsid: "Recharge operators id",
                refnumber: "Your transaction id",
              })}
            ></JSONPretty>
          </StyledContent>
        </StyledBodyContainer>
        <StyledApiHeading>Status Check API</StyledApiHeading>
        <StyledBodyContainer>
          <StyledHeading>API Request</StyledHeading>
          <StyledContent>
            <p>
              <b>Method :</b> GET
            </p>
            <p>
              <b>URL :</b>{" "}
              <code>{`${API_BASE_URL}/public/status?apiid=[apiid]&apitoken=[apitoken]&transactionsid=[transactionsid]&refnumber=[refnumber]`}</code>
            </p>
            <TableBuilder
              tableHeadings={[
                {
                  title: "Field",
                  dataSelector: "field",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Description",
                  dataSelector: "description",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Mandatory/Optional",
                  dataSelector: "fieldType",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Remark",
                  dataSelector: "remark",
                  dataType: "string",
                  canSort: false,
                },
              ]}
              tableData={[
                {
                  field: "apiid",
                  description: "Api id provided by us",
                  fieldType: "Mandatory",
                  remark: "",
                },
                {
                  field: "apitoken",
                  description: "Api token provided by us",
                  fieldType: "Mandatory",
                  remark: "",
                },
                {
                  field: "transactionsid",
                  description: "Our transactions id",
                  fieldType: "Mandatory",
                  remark: "",
                },
                {
                  field: "refnumber",
                  description: "Your transactions id",
                  fieldType: "Optional",
                  remark: "",
                },
              ]}
            />
          </StyledContent>
        </StyledBodyContainer>
        <StyledBodyContainer>
          <StyledHeading>Response</StyledHeading>
          <StyledContent>
            <p>
              <b>Response Type :</b> JSON
            </p>
            <JSONPretty
              id="json-pretty"
              data={JSON.stringify({
                status: "Success/Failed/Pending",
                message: "Transaction Information",
                transactionsid: "Our transactions id",
                number: "Recharge number",
                amount: "Recharge amount",
                margin: "Your margin",
                operator: "Operator name",
                operatorsid: "Recharge operators id",
                refnumber: "Your transaction id",
              })}
            ></JSONPretty>
          </StyledContent>
        </StyledBodyContainer>

        <StyledApiHeading>Balance Check API</StyledApiHeading>
        <StyledBodyContainer>
          <StyledHeading>API Request</StyledHeading>
          <StyledContent>
            <p>
              <b>Method :</b> GET
            </p>
            <p>
              <b>URL :</b>{" "}
              <code>{`${API_BASE_URL}/public/balance?apiid=[apiid]&apitoken=[apitoken]`}</code>
            </p>
            <TableBuilder
              tableHeadings={[
                {
                  title: "Field",
                  dataSelector: "field",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Description",
                  dataSelector: "description",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Mandatory/Optional",
                  dataSelector: "fieldType",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Remark",
                  dataSelector: "remark",
                  dataType: "string",
                  canSort: false,
                },
              ]}
              tableData={[
                {
                  field: "apiid",
                  description: "Api id provided by us",
                  fieldType: "Mandatory",
                  remark: "",
                },
                {
                  field: "apitoken",
                  description: "Api token provided by us",
                  fieldType: "Mandatory",
                  remark: "",
                },
              ]}
            />
          </StyledContent>
        </StyledBodyContainer>
        <StyledBodyContainer>
          <StyledHeading>Response</StyledHeading>
          <StyledContent>
            <p>
              <b>Response Type :</b> JSON
            </p>
            <JSONPretty
              id="json-pretty"
              data={JSON.stringify(
                data?.nonGstWalletStatus === "Active"
                  ? {
                      status: "Success/Failed",
                      message: "Your available balance",
                      gstBuyerWallet: "GST buyer wallet balance",
                      gstSellerWallet: "GST seller wallet balance",
                      nonGstBuyerWallet: "Non-GST buyer wallet balance",
                      nonGstSellerWallet: "Non-GST seller wallet balance",
                      totalBuyerWallet: "total buyer wallet balance",
                      totalSellerWallet: "total seller wallet balance",
                    }
                  : {
                      status: "Success/Failed",
                      message: "Your available balance",
                      gstBuyerWallet: "GST buyer wallet balance",
                      gstSellerWallet: "GST seller wallet balance",
                    }
              )}
            ></JSONPretty>
          </StyledContent>
        </StyledBodyContainer>

        <StyledApiHeading>Complaint API</StyledApiHeading>
        <StyledBodyContainer>
          <StyledHeading>API Request</StyledHeading>
          <StyledContent>
            <p>
              <b>Method :</b> GET
            </p>
            <p>
              <b>URL :</b>{" "}
              <code>{`${API_BASE_URL}/public/complaint?apiid=[apiid]&apitoken=[apitoken]&transactionsid=[transactionsid]`}</code>
            </p>
            <TableBuilder
              tableHeadings={[
                {
                  title: "Field",
                  dataSelector: "field",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Description",
                  dataSelector: "description",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Mandatory/Optional",
                  dataSelector: "fieldType",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Remark",
                  dataSelector: "remark",
                  dataType: "string",
                  canSort: false,
                },
              ]}
              tableData={[
                {
                  field: "apiid",
                  description: "Api id provided by us",
                  fieldType: "Mandatory",
                  remark: "",
                },
                {
                  field: "apitoken",
                  description: "Api token provided by us",
                  fieldType: "Mandatory",
                  remark: "",
                },
                {
                  field: "transactionsid",
                  description: "Our transactions id",
                  fieldType: "Mandatory",
                  remark: "",
                },
              ]}
            />
          </StyledContent>
        </StyledBodyContainer>
        <StyledBodyContainer>
          <StyledHeading>Response</StyledHeading>
          <StyledContent>
            <p>
              <b>Response Type :</b> JSON
            </p>
            <JSONPretty
              id="json-pretty"
              data={JSON.stringify({
                status: "Success/Failed",
                message: "Complaint created successfully",
              })}
            ></JSONPretty>
          </StyledContent>
        </StyledBodyContainer>
        {data?.operators &&
          isArray(data?.operators) &&
          data?.operators.length > 0 && (
            <StyledBodyContainerList>
              <StyledApiHeading>Operators List</StyledApiHeading>
              <TableBuilder
                tableHeadings={[
                  {
                    title: "Operator Name",
                    dataSelector: "operatorName",
                    dataType: "string",
                  },
                  {
                    title: "Operator ID",
                    dataSelector: "id",
                    dataType: "number",
                  },
                ]}
                tableData={data?.operators}
              />
            </StyledBodyContainerList>
          )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(APIDocumentation);
