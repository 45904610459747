import { memo } from 'react';
import styled from 'styled-components';

const StyledTableBody = styled.tbody`
  margin: 0px;
  padding: 0px;
`;

const TableBody = memo(({ children }) => (
  <StyledTableBody>{children}</StyledTableBody>
));

export default TableBody;
