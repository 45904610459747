import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

// const StyledIconButton = styled(Button)`
//   padding: 0px;
//   margin: 0px 5px;
//   color: forestgreen;
//   background-color: transparent;
//   &:hover {
//     background-color: transparent;
//     color: #006200;
//   }
// `;

const StyledIconButton = styled.button`
  font-size: 14px;
  font-weight: normal;
  padding: 0px;
  margin: 0px;
  color: ${({ color }) => (color ? color : '#ff4343')};
  background-color: transparent;
  border: 0px;
  outline: 0px;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    color: ${({ hovercolor }) => (hovercolor ? hovercolor : `#ff0000`)};
  }
`;

const CustomButton = ({
  showIcon = true,
  showTitle = false,
  icon,
  title = "",
  onClick,
  ...rest
}) => {
  return (
    <StyledIconButton onClick={onClick} {...rest}>
      {showIcon && <FontAwesomeIcon icon={icon ? icon : faTrashCan} />}
      {showTitle && ` ${title}`}
    </StyledIconButton>
  );
};

export default CustomButton;
