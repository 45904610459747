import { memo } from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  background-color: #f9fafb;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  padding: 15px;
  margin: 0px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: 100%;
  flex: 2;
  background-color: #f9fafb;
  outline: 0px;
  &:focus {
    outline: 1px solid #dbdbdb;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #e1e1e1;
    border: 1px solid #d3d3d3;
  }
`;

const SearchInput = memo(
  ({
    value = "",
    onChange = () => {},
    focus = false,
    disabled = false,
    ...rest
  }) => (
    <StyledInput
      type="search"
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      autoComplete="off"
      disabled={disabled}
      {...rest}
    />
  )
);

export default SearchInput;
