import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { formatCurrency, getAuthData } from "../../utils";

const StyledWalletSectionContainer = styled.section`
  width: 55px;
  height: 100%;
  margin: 0px;
  padding: 0px;
  border-left: 1px solid #e5e5e5;
  position: relative;
  @media (max-width: 600px) {
    position: static;
  }
`;

const StyledWalletIconContainer = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
    height: 100%;
    border-width: 0px;
    background-color: transparent;
    outline: 0px;
    cursor: pointer;
    transition: background-color 0.5s;
    &:hover {
      background-color: #e5e5e5;
    }
    &.active {
      background-color: ${({
        theme: {
          colors: { primaryColor },
        },
      }) => (primaryColor ? primaryColor : `#0078ce`)};
      svg {
        color: #fff;
      }
    }
    svg {
      margin: 0px;
      padding: 0px;
      font-size: 20px;
      color: #707070;
    }
  }
`;

const StyledWalletContent = styled.section`
  position: absolute;
  width: 280px;
  height: auto;
  background-color: #fff;
  top: 55px;
  right: 0px;
  border-radius: 0px;
  box-shadow: 0px 5px 15px -10px #000000;
  z-index: 1;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledWalletContentHeader = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  background-color: ${({
    theme: {
      colors: { primaryColor },
    },
  }) => (primaryColor ? primaryColor : `#0078ce`)};
  h3 {
    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
    margin: 0px;
    padding: 12px;
    text-align: left;
  }
`;

const StyledDueContainer = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  border-bottom: 1px solid #e5e5e5;
`;

const StyledDueSpan = styled.span`
  font-size: 14px;
  display: block;
  color: #848484;
  margin: 0px;
  font-style: normal;
  text-transform: capitalize;
  background-color: #f6f6f6;
  padding: 10px;
`;

const WalletSection = ({
  gstBuyerWallet,
  gstSellerWallet,
  nonGstBuyerWallet,
  nonGstSellerWallet,
}) => {
  const {
    userProfile: { nonGstWalletStatus },
  } = getAuthData();

  const [expandWallet, setExpandWallet] = useState(false);
  const walletRef = useRef(null);

  const onClickExpandWallet = (e) => {
    e.preventDefault();
    setExpandWallet((expandWallet) => !expandWallet);
  };

  const closeWalletList = useCallback((e) => {
    if (walletRef.current && !walletRef.current.contains(e.target)) {
      setExpandWallet(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", closeWalletList, true);
    return () => {
      document.removeEventListener("click", closeWalletList, true);
    };
  }, [closeWalletList]);

  return (
    <StyledWalletSectionContainer ref={walletRef}>
      <StyledWalletIconContainer>
        <button
          type="button"
          className={expandWallet ? `active` : ``}
          onClick={onClickExpandWallet}
        >
          <FontAwesomeIcon icon={faWallet} />
        </button>
      </StyledWalletIconContainer>
      {expandWallet && (
        <StyledWalletContent>
          <StyledWalletContentHeader>
            <h3>Wallet Balance</h3>
          </StyledWalletContentHeader>
          <StyledDueContainer>
            <StyledDueSpan>
              GST Buyer Balance : {formatCurrency(gstBuyerWallet)}
            </StyledDueSpan>
            <StyledDueSpan>
              GST Seller Balance : {formatCurrency(gstSellerWallet)}
            </StyledDueSpan>
            {nonGstWalletStatus === "Active" && (
              <>
                <StyledDueSpan>
                  Non GST Buyer Balance : {formatCurrency(nonGstBuyerWallet)}
                </StyledDueSpan>
                <StyledDueSpan>
                  Non GST Seller Balance : {formatCurrency(nonGstSellerWallet)}
                </StyledDueSpan>
              </>
            )}
          </StyledDueContainer>
        </StyledWalletContent>
      )}
    </StyledWalletSectionContainer>
  );
};

export default WalletSection;
