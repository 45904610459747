import { memo, useState } from 'react';
import styled from 'styled-components';

const StyledSwitchContainer = styled.section`
  width: 40px;
  height: 20px;
  margin: 0px;
  padding: 3px;
  background-color: #b9b9b9;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  font-size: 9px;
  color: #ffffff;
  transition: background-color 0.5s, color 0.5s;
  display: inline-block;
  &.active {
    background-color: forestgreen;
  }
`;

const StyledSwitchText = styled.section`
  width: auto;
  height: auto;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 9px;
  left: 25px;
  transition: left 0.5s;
  &.active {
    left: 6px;
  }
`;

const StyledSwitchThumb = styled.section`
  width: 20px;
  height: 20px;
  margin: 0px;
  padding: 0px;
  border-radius: 100px;
  background-color: #ffffff;
  position: absolute;
  top: 3px;
  left: 3px;
  transition: left 0.5s, background-color 0.5s;
  &.active {
    left: 23px;
  }
`;

const Switch = memo(({ value, onChange, showSwitchLabels = false }) => {
  const [switchValue, setSwitchValue] = useState(value);

  const onClickSwitch = () => {
    onChange(!switchValue);
    setSwitchValue((switchValue) => !switchValue);
  };

  return (
    <StyledSwitchContainer
      className={switchValue ? `active` : ``}
      onClick={onClickSwitch}
    >
      {showSwitchLabels && (
        <StyledSwitchText className={switchValue ? `active` : ``}>
          {switchValue ? 'ON' : 'OFF'}
        </StyledSwitchText>
      )}
      <StyledSwitchThumb
        className={switchValue ? `active` : ``}
      ></StyledSwitchThumb>
    </StyledSwitchContainer>
  );
});

export default Switch;
