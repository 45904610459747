import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledMainAreaContainer,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  Button,
} from "../../components/FormElements";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { isEmpty } from "lodash";
import { getReturnUrl } from "../../utils";

const SwitchFunds = () => {
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: "",
    responseMessage: "",
    fromWallet: "",
    toWallet: "",
    amount: "",
  });

  const readMeta = async () => {
    try {
      const response = await API.get(`/meta`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data: meta } = useQuery({
    queryKey: ["meta"],
    queryFn: readMeta,
  });

  const fromWalletOptions = [
    {
      name: "-- SELECT FROM WALLET --",
      value: "",
    },
  ];

  const toWalletOptions = [
    {
      name: "-- SELECT TO WALLET --",
      value: "",
    },
  ];

  if (meta?.gstWalletStatus === "Active") {
    fromWalletOptions.push({
      name: "GST Seller",
      value: "gstSellerWallet",
    });
    toWalletOptions.push({
      name: "GST Buyer",
      value: "gstBuyerWallet",
    });
  }
  if (meta?.nonGstWalletStatus === "Active") {
    fromWalletOptions.push({
      name: "Non-GST Seller",
      value: "nonGstSellerWallet",
    });
    toWalletOptions.push({
      name: "Non-GST Buyer",
      value: "nonGstBuyerWallet",
    });
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));
    try {
      const response = await API.post("/payments/switch-funds", {
        fromWallet: state.fromWallet,
        toWallet: state.toWallet,
        amount: state.amount,
      });
      const { status, message } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.SUCCESS,
          responseMessage: message,
          fromWallet: "",
          toWallet: "",
          amount: "",
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            responseStatus: "",
            responseMessage: "",
          }));
          window.location.href = getReturnUrl("create");
        }, 3000);
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const renderPageHeading = () => (
    <PageHeader title="Switch Funds" showBackButton={true} />
  );

  return (
    <>
      {renderPageHeading()}
      <StyledContentContainer>
        <StyledMainAreaContainer>
          <Form
            action="#"
            method="POST"
            onSubmit={onSubmit}
            style={{ maxWidth: "500px" }}
          >
            <Fieldset>
              <Label required>From Wallet</Label>
              <Dropdown
                value={state.fromWallet}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    fromWallet: value,
                    toWallet: "",
                  }))
                }
                placeholder="From Wallet"
                disabled={state.isLoading}
                options={fromWalletOptions}
              />
            </Fieldset>
            <Fieldset>
              <Label required>To Wallet</Label>
              <Dropdown
                value={state.toWallet}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    toWallet: value,
                  }))
                }
                placeholder="To Wallet"
                disabled={state.isLoading}
                options={toWalletOptions.filter((item) => {
                  if (!isEmpty(state.fromWallet)) {
                    if (
                      state.fromWallet === "nonGstSellerWallet" &&
                      item.value === "gstBuyerWallet"
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  } else {
                    return true;
                  }
                })}
              />
            </Fieldset>
            <Fieldset>
              <Label required>Amount</Label>
              <TextInput
                value={state.amount}
                isNumeric={true}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    amount: value,
                  }))
                }
                placeholder="Amount"
                disabled={state.isLoading}
              />
            </Fieldset>
            {state.responseStatus && state.responseMessage && (
              <Fieldset>
                <MessageBox
                  status={state.responseStatus}
                  message={state.responseMessage}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={state.isLoading}>Submit</Button>
            </Fieldset>
          </Form>
        </StyledMainAreaContainer>
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(SwitchFunds);
