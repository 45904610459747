import { memo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const StyledLogoutButton = styled.button`
  width: 56px;
  height: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-left: 1px solid #ebebeb;
  &:hover {
    background-color: #ffebeb;
  }
  svg {
    font-size: 20px;
    color: #ff4343;
  }
`;

const LogoutSection = memo(({ onClickLogout }) => (
  <StyledLogoutButton onClick={onClickLogout}>
    <FontAwesomeIcon icon={faSignOutAlt} />
  </StyledLogoutButton>
));

export default LogoutSection;
