import { useCallback, useEffect, useState } from "react";
import {
  AuthBackground,
  AuthContainer,
  AuthSliderContainer,
  AuthWelcomeText,
  AuthTaglineText,
  AuthToolsImage,
  AuthFormContainer,
  AuthFormInnerContainer,
  AuthLogoContainer,
  AuthFormBody,
} from "../components/styled";
import Logo from "../components/Logo/Logo";
import Header from "../components/Header/Header";
import Navigation from "../components/Navigation/Navigation";
import Footer from "../components/Footer/Footer";
import API from "../api";
import { clearLocalStorage, setLocalStorage } from "../utils";
import { RESPONSE_STATUSES } from "../constants";

const widthAuthHOC = (Component) => (props) => {
  return (
    <AuthBackground>
      <AuthContainer>
        <AuthSliderContainer>
          <AuthToolsImage src="./auth-footer.png" alt="API Exchange" />
          <AuthWelcomeText>Welcome</AuthWelcomeText>
          <AuthTaglineText>
            Streamline your workflows with Automation!
          </AuthTaglineText>
        </AuthSliderContainer>
        <AuthFormContainer>
          <AuthFormInnerContainer>
            <AuthLogoContainer>
              <Logo />
            </AuthLogoContainer>
            <AuthFormBody>
              <Component {...props} />
            </AuthFormBody>
          </AuthFormInnerContainer>
          <Footer />
        </AuthFormContainer>
      </AuthContainer>
    </AuthBackground>
  );
};

const withAppHOC = (Component) => (props) => {
  const [profile, setProfile] = useState(null);
  const readProfile = useCallback(async () => {
    const response = await API.get("/authentication/profile");
    const { status, data } = response.data;
    if (status === RESPONSE_STATUSES.SUCCESS) {
      setProfile(data);
      setLocalStorage("userProfile", JSON.stringify(data));
    }
  }, []);

  const onClickLogout = (e) => {
    e.preventDefault();
    API.delete("/authentication/logout");
    clearLocalStorage();
    window.location.href = "/login";
  };

  useEffect(() => {
    readProfile();
  }, [readProfile]);

  return (
    <>
      <Header
        fullName={profile?.firstName ? profile.firstName : "My Account"}
        {...profile}
        onClickLogout={onClickLogout}
      />
      <Navigation />
      <Component {...props} />
      <Footer />
    </>
  );
};

const withPublicHOC = (Component) => (props) => {
  return (
    <>
      <Component {...props} />
    </>
  );
};

export { widthAuthHOC, withAppHOC, withPublicHOC };
