import { useQuery } from "@tanstack/react-query";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledGridLayout,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { MessageBox } from "../../components/FormElements";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import SessionCard from "../../components/Cards/SessionCard";
import NoData from "../../components/NoData/NoData";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";

const Sessions = () => {
  const fetchData = async () => {
    const response = await API.get(`/authentication/sessions`);
    return response.data;
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["sessions"],
    queryFn: fetchData,
  });

  const reloadData = async () => await refetch();

  const renderPageHeading = () => <PageHeader title="Your Active Sessions" />;

  if (isLoading || isRefetching) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  const onClickLogout = async (sessionToken) => {
    await API.delete(`/authentication/logout?token=${sessionToken}`);
    reloadData();
  };

  return (
    <>
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        {data?.data && data?.length === 0 ? (
          <NoData />
        ) : (
          <StyledGridLayout>
            {data?.data.map((item, index) => (
              <SessionCard
                key={index}
                {...item}
                forceLogout={() => onClickLogout(item.sessionToken)}
              />
            ))}
          </StyledGridLayout>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(Sessions);
