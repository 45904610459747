import { useState } from 'react';
import { withAppHOC } from '../../hoc';
import {
  StyledContentContainer,
  StyledMainAreaContainer,
} from '../../components/styled';
import PageHeader from '../../components/PageHeader/PageHeader';
import {
  Form,
  Fieldset,
  Label,
  PasswordInput,
  MessageBox,
  Button,
} from '../../components/FormElements';
import API from '../../api';
import { RESPONSE_STATUSES } from '../../constants';

const ChangePassword = () => {
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: '',
    responseMessage: '',
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: '',
      responseMessage: '',
    }));
    try {
      const response = await API.post('/authentication/change-password', {
        oldPassword: state.oldPassword,
        newPassword: state.newPassword,
        confirmNewPassword: state.confirmNewPassword,
      });
      const { status, message } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.SUCCESS,
          responseMessage: message,
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            responseStatus: '',
            responseMessage: '',
          }));
        }, 3000);
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  return (
    <>
      <PageHeader title='Change your password' />
      <StyledContentContainer>
        <StyledMainAreaContainer>
          <Form
            action='#'
            method='POST'
            onSubmit={onSubmit}
            style={{ maxWidth: '500px' }}
          >
            <Fieldset>
              <Label required>Old Password</Label>
              <PasswordInput
                value={state.oldPassword}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    oldPassword: value,
                  }))
                }
                placeholder='Old Password'
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>New Password</Label>
              <PasswordInput
                value={state.newPassword}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    newPassword: value,
                  }))
                }
                placeholder='New Password'
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>Confirm New Password</Label>
              <PasswordInput
                value={state.confirmNewPassword}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    confirmNewPassword: value,
                  }))
                }
                placeholder='Confirm New Password'
                disabled={state.isLoading}
              />
            </Fieldset>
            {state.responseStatus && state.responseMessage && (
              <Fieldset>
                <MessageBox
                  status={state.responseStatus}
                  message={state.responseMessage}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={state.isLoading}>Save</Button>
            </Fieldset>
          </Form>
        </StyledMainAreaContainer>
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(ChangePassword);
