import { memo, useState, useEffect, useRef, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Image } from '../Image';

const StyledAccountSectionContainer = styled.section`
  width: 56px;
  height: 100%;
  margin: 0;
  padding: 0;
  border-left: 1px solid #ebebeb;
  position: relative;
  @media (max-width: 600px) {
    position: static;
  }
`;

const StyledAccountIconContainer = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
    height: 100%;
    border-width: 0;
    background-color: transparent;
    outline: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 30px;
      height: auto;
      margin: 0;
      padding: 2px;
      border: 2px solid #f4f4f4;
      border-radius: 100px;
    }
    &:hover {
      background-color: #f4f4f4;
    }
    &.active {
      background-color: ${({ theme: { colors } }) =>
        colors.primaryColor || `#3049f8`};
      svg {
        color: #fff;
      }
    }
  }
`;

const StyledAccountContent = styled.section`
  position: absolute;
  width: 280px;
  height: auto;
  background-color: #fff;
  top: 55px;
  right: 0;
  border-radius: 0;
  box-shadow: 0 5px 15px -10px #000000;
  z-index: 999999;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledAccountContentHeader = styled.section`
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: ${({ theme: { colors } }) =>
    colors.primaryColor || `#3049f8`};
  h3 {
    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
    margin: 0;
    padding: 12px;
    text-align: left;
  }
`;

const StyledAccountProfilePhotoContainer = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 15px 0;
  border-bottom: 1px solid #ebf5ff;
  img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    padding: 5px;
    border: 5px solid #f4f4f4;
    display: block;
    border-radius: 100%;
  }
`;

const StyledAccountContentLinks = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      display: block;
      border-bottom: 1px solid #ebebeb;
      a {
        font-size: 14px;
        text-decoration: none;
        outline: 0;
        display: block;
        color: #000;
        margin: 0;
        padding: 12px;
        &.active {
          background-color: #f4f4f4;
        }
      }
      &:hover {
        background-color: #f4f4f4;
      }
    }
    &:last-child {
      border-bottom-width: 0;
    }
  }
`;

const AccountSection = memo(({ fullName, profilePhoto }) => {
  const [expandAccount, setExpandAccount] = useState(false);
  const accountRef = useRef(null);

  const handleOnExpandAccount = (e) => {
    e.preventDefault();
    setExpandAccount(!expandAccount);
  };

  const closeAccountList = useCallback(
    (e) => {
      if (accountRef.current && !accountRef.current.contains(e.target)) {
        setExpandAccount(false);
      }
    },
    [accountRef]
  );

  useEffect(() => {
    document.addEventListener('click', closeAccountList, true);
    return () => {
      document.removeEventListener('click', closeAccountList, true);
    };
  }, [closeAccountList]);

  return (
    <StyledAccountSectionContainer ref={accountRef}>
      <StyledAccountIconContainer>
        <button
          type='button'
          className={expandAccount ? 'active' : ''}
          onClick={handleOnExpandAccount}
        >
          <Image
            source={profilePhoto || '/default-profile-photo.png'}
            alt={fullName}
          />
        </button>
      </StyledAccountIconContainer>
      {expandAccount && (
        <StyledAccountContent>
          <StyledAccountContentHeader>
            <h3>My Account</h3>
          </StyledAccountContentHeader>
          <StyledAccountProfilePhotoContainer>
            <Image
              source={profilePhoto || '/default-profile-photo.png'}
              alt={fullName}
            />
          </StyledAccountProfilePhotoContainer>
          <StyledAccountContentLinks>
            <ul>
              <li>
                <NavLink to='/profile' title='Profile'>
                  Profile
                </NavLink>
              </li>
              <li>
                <NavLink to='/change-password' title='Change Password'>
                  Change Password
                </NavLink>
              </li>
              <li>
                <NavLink to='/logs' title='Logs'>
                  Logs
                </NavLink>
              </li>
              <li>
                <NavLink to='/sessions' title='Sessions'>
                  Sessions
                </NavLink>
              </li>
            </ul>
          </StyledAccountContentLinks>
        </StyledAccountContent>
      )}
    </StyledAccountSectionContainer>
  );
});

export default AccountSection;
