import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { MessageBox, TextInput } from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import {
  camelCaseToString,
  formatCurrency,
  getSearchParams,
} from "../../utils";

const SwitchFundsList = () => {
  const [fFromDate, setFfromDate] = useState("");
  const [fToDate, setFtoDate] = useState("");
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const readData = async () => {
    try {
      const response = await API.get(
        `/payments/switch-funds?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fromDate=${fFromDate}&toDate=${fToDate}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["switch_funds", paginationData],
    queryFn: readData,
    cacheTime: 0,
    staleTime: Infinity,
  });

  const reloadData = async () => await refetch();

  const filterData = async (e) => {
    e.preventDefault();
    await refetch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setFfromDate("");
    setFtoDate("");
    refetch();
  };

  const renderPageHeading = () => (
    <PageHeader
      title="Switch Funds List"
      showAddButton={true}
      addLink="create"
      addTitle="Switch Funds"
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      <Loader isLoading={isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <TextInput
                type="date"
                value={fFromDate}
                onChange={setFfromDate}
                placeholder="From Date"
                disabled={isLoading}
              />
              <TextInput
                type="date"
                value={fToDate}
                onChange={setFtoDate}
                placeholder="To Date"
                disabled={isLoading}
              />
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "From Wallet",
                  dataSelector: "fromWallet",
                  dataType: "string",
                  cellrenderer: (value) => camelCaseToString(value),
                },
                {
                  title: "To Wallet",
                  dataSelector: "toWallet",
                  dataType: "string",
                  cellrenderer: (value) => camelCaseToString(value),
                },
                {
                  title: "Amount",
                  dataSelector: "amount",
                  dataType: "number",
                  cellrenderer: (value) => formatCurrency(value),
                },
                {
                  title: "Created",
                  dataSelector: "createdOn",
                  dataType: "string",
                  cellrenderer: (value) =>
                    moment(value).format("Do MMM YYYY, hh:mm:ss A"),
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(SwitchFundsList);
