import { memo } from 'react';
import styled from 'styled-components';

const StyledTableRow = styled.tr`
  margin: 0px;
  padding: 0px;
  border-bottom: 1px solid #ebebeb;
  ${({ isselected }) => isselected === 'Yes' && `background-color: #f5fbff;`}
  &:last-child {
    border-bottom: 0px;
  }
`;

const TableRow = memo(({ children, isSelected = 'No' }) => (
  <StyledTableRow isselected={isSelected}>{children}</StyledTableRow>
));

export default TableRow;
