import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import moment from "moment";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
  StyledStatusPill,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { MessageBox, Dropdown, TextInput } from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getSearchParams } from "../../utils";

const BuyerTransactionsComplaintsList = () => {
  const [fOperatorsId, setFoperatorsId] = useState("");
  const [fCirclesId, setFcirclesId] = useState("");
  const [fCategoriesId, setFcategoriesId] = useState("");
  const [fComplaintResolutionStatus, setFcomplaintResolutionStatus] =
    useState("All");
  const [fRechargeNumber, setFrechargeNumber] = useState("");
  const [fFromDate, setFfromDate] = useState("");
  const [fToDate, setFtoDate] = useState("");
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const readData = async () => {
    try {
      const response = await API.get(
        `/buyer/transactions?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fOperatorsId=${fOperatorsId}&fCirclesId=${fCirclesId}&fCategoriesId=${fCategoriesId}&fRechargeNumber=${fRechargeNumber}&fFromDate=${fFromDate}&fToDate=${fToDate}&fComplaintResolutionStatus=${fComplaintResolutionStatus}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["complaints_transactions", paginationData],
    queryFn: readData,
    cacheTime: 0,
    staleTime: Infinity,
  });

  const reloadData = async () => await refetch();

  const readMeta = async () => {
    try {
      const response = await API.get(`/meta`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data: meta } = useQuery({
    queryKey: ["meta"],
    queryFn: readMeta,
  });

  const filterData = async (e) => {
    e.preventDefault();
    await refetch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setFoperatorsId("");
    setFcirclesId("");
    setFcategoriesId("");
    setFcomplaintResolutionStatus("");
    setFrechargeNumber("");
    setFfromDate("");
    setFtoDate("");
    refetch();
  };

  const renderPageHeading = () => (
    <PageHeader
      title="Complaint Transactions"
      showAddButton={false}
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  const operatorsOptions = [
    {
      name: "OPERATOR",
      value: "",
    },
  ];
  !isEmpty(meta?.operators) &&
    meta?.operators?.forEach(({ operatorName, id }) =>
      operatorsOptions?.push({ name: operatorName, value: id })
    );

  const circlesOptions = [
    {
      name: "CIRCLE",
      value: "",
    },
  ];
  !isEmpty(meta?.circles) &&
    meta?.circles?.forEach(({ circleName, id }) =>
      circlesOptions?.push({ name: circleName, value: id })
    );

  const categoriesOptions = [
    {
      name: "CATEGORY",
      value: "",
    },
  ];
  !isEmpty(meta?.categories) &&
    meta?.categories?.forEach(({ category, id }) =>
      categoriesOptions?.push({ name: category, value: id })
    );

  const resolutionStatusOptions = [
    {
      name: "RESOLUTION STATUS",
      value: "",
    },
    {
      name: "All",
      value: "All",
    },
    {
      name: "Pending",
      value: "Pending",
    },
    {
      name: "Resolved",
      value: "Resolved",
    },
  ];

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      <Loader isLoading={isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <Dropdown
                value={fOperatorsId}
                onChange={setFoperatorsId}
                placeholder="Operator"
                disabled={isLoading}
                options={operatorsOptions}
              />
              <Dropdown
                value={fCirclesId}
                onChange={setFcirclesId}
                placeholder="Circle"
                disabled={isLoading}
                options={circlesOptions}
              />
              <Dropdown
                value={fCategoriesId}
                onChange={setFcategoriesId}
                placeholder="Category"
                disabled={isLoading}
                options={categoriesOptions}
              />
              <Dropdown
                value={fComplaintResolutionStatus}
                onChange={setFcomplaintResolutionStatus}
                placeholder="Resolution Status"
                disabled={isLoading}
                options={resolutionStatusOptions}
              />
              <TextInput
                value={fRechargeNumber}
                onChange={setFrechargeNumber}
                placeholder="Recharge Number"
                disabled={isLoading}
              />
              <TextInput
                type="date"
                value={fFromDate}
                onChange={setFfromDate}
                placeholder="From Date"
                disabled={isLoading}
              />
              <TextInput
                type="date"
                value={fToDate}
                onChange={setFtoDate}
                placeholder="To Date"
                disabled={isLoading}
              />
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "Operator",
                  dataSelector: "operatorName",
                  dataType: "string",
                },
                {
                  title: "Recharge Number",
                  dataSelector: "rechargeNumber",
                  dataType: "string",
                },
                {
                  title: "Amount",
                  dataSelector: "rechargeAmount",
                  dataType: "number",
                  cellrenderer: (value) => formatCurrency(value),
                },
                {
                  title: "Transaction Status",
                  dataSelector: "rechargeStatus",
                  dataType: "string",
                  cellrenderer: (value) => (
                    <StyledStatusPill
                      className={
                        value === "Success"
                          ? "green"
                          : value === "Failed"
                          ? "red"
                          : "yellow"
                      }
                    >
                      {value}
                    </StyledStatusPill>
                  ),
                },
                {
                  title: "Complaint Status",
                  dataSelector: "complaintResolutionStatus",
                  dataType: "string",
                  cellrenderer: (value, item) => (
                    <StyledStatusPill
                      className={
                        value === "Resolved" &&
                        item.rechargeStatus === "Success"
                          ? "red"
                          : value === "Resolved" &&
                            item.rechargeStatus === "Failed"
                          ? "green"
                          : item.complaintReopened === "Yes"
                          ? "blue"
                          : "yellow"
                      }
                    >
                      {value === "Resolved" && item.rechargeStatus === "Success"
                        ? "Rejected"
                        : value === "Resolved" &&
                          item.rechargeStatus === "Failed"
                        ? "Accepted"
                        : value === "Pending" &&
                          item.complaintReopened === "Yes"
                        ? "Reopened"
                        : "Pending"}
                    </StyledStatusPill>
                  ),
                },
                {
                  title: "Complaint Raised Time",
                  dataSelector: "complaintRaisedTimestamp",
                  dataType: "string",
                  cellrenderer: (value) =>
                    value
                      ? moment(value).format("Do MMM YYYY, hh:mm:ss A")
                      : "N/A",
                },
                {
                  title: "Complaint Resolution Time",
                  dataSelector: "complaintResolutionTimestamp",
                  dataType: "string",
                  cellrenderer: (value) =>
                    value
                      ? moment(value).format("Do MMM YYYY, hh:mm:ss A")
                      : "N/A",
                },
                {
                  title: "Sellers Remark",
                  dataSelector: "complaintSellerRemark",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "Operators Id",
                  dataSelector: "rechargeOperatorsId",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "Transaction Time",
                  dataSelector: "createdOn",
                  dataType: "string",
                  cellrenderer: (value) =>
                    moment(value).format("Do MMM YYYY, hh:mm:ss A"),
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(BuyerTransactionsComplaintsList);
