import React, { useEffect, useCallback, memo, useMemo } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const StyledPaginationContainer = styled.section`
  width: calc(100% - 20px);
  height: auto;
  margin: 20px 0px 0px;
  padding: 10px;
  display: flex;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  border: 1px solid #ebebeb;
`;

const StyledPageinationCounter = styled.section`
  width: auto;
  min-width: 150px;
  height: auto;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 12px;
  margin: 0px 0px 0px 15px;
  padding: 0px;
  color: #000000;
`;

const StyledPagination = styled.section`
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
`;

const StyledButton = styled.button`
  font-size: 12px;
  font-weight: normal;
  background-color: transparent;
  padding: 0px;
  margin: 0px 5px;
  width: 35px;
  height: 35px;
  outline: 0px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0px;
  color: #3049f8;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
    margin: 0px 2px;
  }
  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    background-color: ${({ disabled }) =>
      disabled ? 'transparent' : '#3049f8'};
    color: ${({ disabled }) => (disabled ? '#3049f8' : '#ffffff')};
  }
`;

const StyledPaginationNumber = styled(StyledButton)`
  &:hover {
    background-color: #3049f8;
    color: #ffffff;
  }
  &.active {
    background-color: #3049f8;
    color: #ffffff;
  }
`;

const StyledDots = styled.span`
  ${StyledButton} {
    color: #3049f8;
    opacity: 0.5;
  }
`;

const StyledNumberOfRecords = styled.section`
  width: auto;
  min-width: 150px;
  height: auto;
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  color: #000000;
`;

const StyledSelect = styled.select`
  font-size: 12px;
  outline: 0px;
  padding: 5px;
  margin: 0px 5px 0px 0px;
  background-color: transparent;
  border: 0px solid #979797;
  border-radius: 30px;
  color: #000000;
`;

const Pagination = memo(
  ({ totalRecords, paginationData, setPaginationData }) => {
    const [searchParams] = useSearchParams();
    const { pageNumber, recordsPerPage } = paginationData;
    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    const recordsPerPageOptions = useMemo(
      () =>
        totalRecords >= 10
          ? [10, 30, 50, 100, 200, 500].filter((item) => item <= totalRecords)
          : [10],
      [totalRecords]
    );

    const onClickPrevPage = () => {
      setPaginationData({
        pageNumber: Math.max(1, pageNumber - 1),
        recordsPerPage,
      });
    };

    const onClickNextPage = () => {
      setPaginationData({
        pageNumber: Math.min(totalPages, pageNumber + 1),
        recordsPerPage,
      });
    };

    const onClickPageNumber = (pageNumber) => {
      setPaginationData({
        pageNumber,
        recordsPerPage,
      });
    };

    const onChangeNumberOfRecords = (event) => {
      event.preventDefault();

      setPaginationData({
        pageNumber: 1,
        recordsPerPage: Number(event.target.value),
      });
    };

    const generatePageNumbers = useCallback(() => {
      let pageNumbers = [];
      const paginationStartNumber = Math.max(
        1,
        Math.ceil(pageNumber / 5) * 5 - 4
      );
      const totalSections = Math.ceil(totalPages / 5);

      for (
        let i = paginationStartNumber;
        i <= Math.min(totalPages, paginationStartNumber + 4);
        i++
      ) {
        pageNumbers.push(i);
      }
      if (totalSections > 1 && totalSections !== Math.ceil(pageNumber / 5)) {
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
      if (Math.ceil(pageNumber / 5) > 1) {
        pageNumbers.unshift('...');
        pageNumbers.unshift(1);
      }

      return pageNumbers;
    }, [pageNumber, totalPages]);

    const getSearchParam = useCallback(
      (key) => {
        return Number(searchParams.get(key));
      },
      [searchParams]
    );

    useEffect(() => {
      const newPageNumber = getSearchParam('pageNumber');
      const newRecordsPerPage = getSearchParam('recordsPerPage');
      if (
        (pageNumber !== newPageNumber ||
          recordsPerPage !== newRecordsPerPage) &&
        newPageNumber !== 0 &&
        newRecordsPerPage !== 0
      ) {
        setPaginationData({
          pageNumber: newPageNumber,
          recordsPerPage: newRecordsPerPage,
        });
      }
    }, [
      pageNumber,
      recordsPerPage,
      searchParams,
      getSearchParam,
      setPaginationData,
    ]);

    return (
      <StyledPaginationContainer>
        <StyledPageinationCounter>
          Page {pageNumber} of {totalPages}
        </StyledPageinationCounter>
        <StyledPagination>
          <StyledButton
            onClick={onClickPrevPage}
            disabled={pageNumber === 1}
            key='prev'
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </StyledButton>
          {generatePageNumbers().map((item, index) =>
            item === '...' ? (
              <StyledDots key={`pg-${index}`}>...</StyledDots>
            ) : (
              <StyledPaginationNumber
                onClick={() => onClickPageNumber(item)}
                className={pageNumber === item && 'active'}
                key={`pg-${index}`}
              >
                {item}
              </StyledPaginationNumber>
            )
          )}
          <StyledButton
            onClick={onClickNextPage}
            disabled={pageNumber === totalPages}
            key='next'
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </StyledButton>
        </StyledPagination>
        <StyledNumberOfRecords>
          Records
          <StyledSelect
            value={recordsPerPage}
            onChange={onChangeNumberOfRecords}
          >
            {recordsPerPageOptions.map((item) => (
              <option
                value={item}
                key={item}
                style={{ color: '#000' }}
                {...(item === recordsPerPage && `selected`)}
              >
                {item}
              </option>
            ))}
          </StyledSelect>
        </StyledNumberOfRecords>
      </StyledPaginationContainer>
    );
  }
);

export default Pagination;
