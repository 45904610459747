import { memo } from "react";
import styled from "styled-components";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faComputer,
  faLocation,
  faMobileAlt,
  faNetworkWired,
  faSignOut,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import {
  faAndroid,
  faApple,
  faIntercom,
  faUbuntu,
  faWindows,
} from "@fortawesome/free-brands-svg-icons";

const StyledCard = styled.section`
  width: 100%;
  max-width: 350px;
  height: auto;
  border-radius: 10px;
`;

const StyledDetails = styled.section`
  border: 1px solid #ebebeb;
  background-color: #fff;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`;

const StyledDetail = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 15px 15px 0px 15px;
  &:last-child {
    padding: 15px;
  }
  svg {
    color: #3049f8;
  }
`;

const StyledIconContainer = styled.section``;

const StyledTextContainer = styled.section`
  flex: 1;
`;

const StyledLabel = styled.p`
  font-size: 12px;
  font-weight: normal;
  color: #3049f8;
  margin: 0px 0px 5px 0px;
  padding: 0px;
`;

const StyledDetailText = styled.p`
  font-size: 13px;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px;
`;

const StyledButtonContainer = styled.section`
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`;

const StyledLogoutButton = styled.button`
  width: 100%;
  border: 0px;
  margin: 0px;
  padding: 15px;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  text-align: center;
  color: #ff0000;
  background-color: rgb(255 214 214);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  outline: 0px;
  svg {
    margin-right: 5px;
  }
  &:hover {
    background-color: #ffbdbd;
  }
`;

const SessionCard = memo(
  ({
    ipAddress,
    location,
    platform,
    operatingSystem,
    device,
    createdOn,
    forceLogout,
  }) => (
    <StyledCard>
      <StyledDetails>
        <StyledDetail>
          <StyledIconContainer>
            <FontAwesomeIcon icon={faNetworkWired} />
          </StyledIconContainer>
          <StyledTextContainer>
            <StyledLabel>IP Address</StyledLabel>
            <StyledDetailText>{ipAddress}</StyledDetailText>
          </StyledTextContainer>
        </StyledDetail>
        <StyledDetail>
          <StyledIconContainer>
            <FontAwesomeIcon
              icon={
                device === "Desktop"
                  ? faComputer
                  : device === "Desktop"
                  ? faMobileAlt
                  : faTable
              }
            />
          </StyledIconContainer>
          <StyledTextContainer>
            <StyledLabel>Device Type</StyledLabel>
            <StyledDetailText>{device}</StyledDetailText>
          </StyledTextContainer>
        </StyledDetail>
        <StyledDetail>
          <StyledIconContainer>
            <FontAwesomeIcon icon={faIntercom} />
          </StyledIconContainer>
          <StyledTextContainer>
            <StyledLabel>Platform</StyledLabel>
            <StyledDetailText>{platform}</StyledDetailText>
          </StyledTextContainer>
        </StyledDetail>
        <StyledDetail>
          <StyledIconContainer>
            <FontAwesomeIcon
              icon={
                operatingSystem === "Windows"
                  ? faWindows
                  : operatingSystem === "Android"
                  ? faAndroid
                  : operatingSystem === "ios"
                  ? faApple
                  : faUbuntu
              }
            />
          </StyledIconContainer>
          <StyledTextContainer>
            <StyledLabel>Operating System</StyledLabel>
            <StyledDetailText>{operatingSystem}</StyledDetailText>
          </StyledTextContainer>
        </StyledDetail>
        <StyledDetail>
          <StyledIconContainer>
            <FontAwesomeIcon icon={faLocation} />
          </StyledIconContainer>
          <StyledTextContainer>
            <StyledLabel>Location</StyledLabel>
            <StyledDetailText>{location}</StyledDetailText>
          </StyledTextContainer>
        </StyledDetail>
        <StyledDetail>
          <StyledIconContainer>
            <FontAwesomeIcon icon={faClock} />
          </StyledIconContainer>
          <StyledTextContainer>
            <StyledDetailText>
              <StyledLabel>Date & Time</StyledLabel>
              {moment(createdOn).format("Do MMM YYYY, hh:mm:ss A")}
            </StyledDetailText>
          </StyledTextContainer>
        </StyledDetail>
      </StyledDetails>
      <StyledButtonContainer>
        <StyledLogoutButton onClick={forceLogout}>
          <FontAwesomeIcon icon={faSignOut} />
          Force Logout
        </StyledLogoutButton>
      </StyledButtonContainer>
    </StyledCard>
  )
);

export default SessionCard;
