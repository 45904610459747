import { memo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const StyledCheckboxContainer = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  justify-content: center;
  align-items: center;
`;

const StyledCheckbox = styled.section`
  width: 16px;
  height: 16px;
  background-color: #f9fafb;
  border: 1px solid #d3d3d3;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    background-color: #e1e1e1;
  }
`;

const StyledCheckboxChecked = styled.section`
  background-color: ${({ theme: { colors } }) =>
    colors.primaryColor || `#3049f8`};
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  svg {
    color: #ffffff;
  }
`;

const StyledLabel = styled.span`
  flex: 1;
  font-size: 12px;
  display: inline-block;
  color: #000;
  line-height: 20px;
  a {
    font-size: inherit;
  }
`;

const Checkbox = memo(
  ({ children, value = false, onChange = () => {}, disabled = false }) => (
    <StyledCheckboxContainer>
      <StyledCheckbox
        className={disabled ? 'disabled' : ''}
        onClick={() => !disabled && onChange(!value)}
      >
        {value && (
          <StyledCheckboxChecked>
            <FontAwesomeIcon icon={faCheck} />
          </StyledCheckboxChecked>
        )}
      </StyledCheckbox>
      {children && <StyledLabel>{children}</StyledLabel>}
    </StyledCheckboxContainer>
  )
);

export default Checkbox;
