import { memo } from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  background-color: #f9fafb;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  padding: 15px;
  margin: 0px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: calc(100% - 32px);
  flex: 2;
  background-color: #f9fafb;
  outline: 0px;
  &:focus {
    outline: 1px solid #dbdbdb;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #e1e1e1;
    border: 1px solid #d3d3d3;
  }
`;

const TextInput = memo(
  ({
    value = "",
    onChange = () => {},
    focus = false,
    disabled = false,
    isNumeric = false,
    isMarginAmount = false,
    isNumericRange = false,
    ...rest
  }) => {
    const handleChange = (e) => {
      const inputValue = e.target.value;
      if (isNumeric) {
        const validInput = /^(\d+(\.\d{0,2})?)?$/.test(inputValue);
        if (!validInput) return;
      } else if (isNumericRange) {
      }
      onChange(inputValue, e);
    };

    return (
      <StyledInput
        type="text"
        value={value}
        onChange={handleChange}
        autoComplete="off"
        disabled={disabled}
        {...rest}
      />
    );
  }
);

export default TextInput;
